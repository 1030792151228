import PropTypes from "prop-types";
import React, { useState } from "react";
// import MultiSelect from "@kenshooui/react-multi-select";
// import "@kenshooui/react-multi-select/dist/style.css";

// Multi Select Dropdown
function MultiDropdown({ name, options, handleChange, value}) {
  return (
    {/* <MultiSelect
      id={name}
      name={name}
      items={options}
      selectedItems={value}
      onChange={(e) => handleChange(name, e)}
      showSelectAll={false}
    /> */}
  );
}

MultiDropdown.propTypes = {
  options: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func,
};

MultiDropdown.defaultProps = {
  options: [],
  handleChange: () => {},
};

export default MultiDropdown;
