import { createDecipheriv } from 'crypto';
import { reactLocalStorage } from 'reactjs-localstorage';
const axios = require('axios').default;
const baseURL = process.env.REACT_APP_BASE_URL
const apiBaseURL = process.env.REACT_APP_API_BASE_URL
const backBaseURL = process.env.REACT_APP_BACK_BASE_URL
const algorithm = 'aes-256-cbc';

const config = {
  appName: process.env.REACT_APP_NAME,
  tinyAPIKey: process.env.REACT_APP_TINY_API_KEY,
  googleAPIKey: process.env.REACT_APP_GOOGLE_API_KEY,
  baseURL,
  apiBaseURL,
  backBaseURL,

  decrypt (Securitykey, iv, data) {
    return new Promise((resolve) => {
        try{
          const decipher = createDecipheriv(algorithm, Securitykey, iv);
          let decryptedData = decipher.update(data, "hex", "utf-8");
          decryptedData += decipher.final("utf8");
          resolve(decryptedData)
          return;
        }
        catch(err){
          // console.log(err)
        }
    })
  },

  getAPI(data) {
    return new Promise((resolve, reject) => {
      let url = new URL(`${(data.backUrl)?backBaseURL:baseURL}${data.url}`)
      const master_user_type = reactLocalStorage.get('user_type');
      const master_user_id = reactLocalStorage.get('user_id');
      // url.search = new URLSearchParams({...data.params, code:'EN'}).toString();
      url.search = new URLSearchParams({...data.params, lang_code:'EN', master_user_type, master_user_id}).toString();
      fetch(url).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
  getAPIAxios(data) {
    return new Promise((resolve, reject) => {
      // let url = new URL(`${(data.backUrl)?backBaseURL:baseURL}${data.url}`)
      const master_user_type = reactLocalStorage.get('user_type');
      const master_user_id = reactLocalStorage.get('user_id');
      axios.get(`${(data.backUrl)?backBaseURL:baseURL}${data.url}`, {
        params: {...data.params, lang_code:'EN', master_user_type, master_user_id}
      })
      .then(function (response) {
        // console.log(response);
        resolve(response.data)
        return;
      })
      .catch(function (error) {
        // console.log(error.response.data);
        reject(error.response.data.error)
        return;
      })
    })
  },
  postAPI(data) {
    return new Promise((resolve, reject) => {
      const master_user_type = reactLocalStorage.get('user_type');
      const master_user_id = reactLocalStorage.get('user_id');
      fetch(`${baseURL}${data.url}`, {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body:JSON.stringify({...data.params, code:'EN'})
        body:JSON.stringify({...data.params, lang_code:'EN', master_user_type, master_user_id})
      }).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
  postFormDataAPI(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}${data.url}`, {
        method: "post",
        headers: {
          'contentType': "application/json",
        },
        body:data.params
      }).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
};

export default config;
