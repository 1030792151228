import React, { useState, useEffect } from "react";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import DatePicker from "../../../components/Form/DatePicker";
import FileInput from "../../../components/Form/FileInput";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import configuration from '../../../config';
import { toast } from 'react-toastify';

// List Settings
function Summary({profile_user_id}) {
    const Id = profile_user_id;
    const [fieldArr2, setFieldArr2] = useState([
        { id: 1, title: "Driving Licence Number", name: "id_number", type: "TextInput" },
        { id: 1, title: "Licence Expiry Date", name: "license_expiry_date", type: "DatePicker" }
    ]);
    const [fields, setFields] = useState({});
    const [docs, setDocs] = useState({});
    const [listDocs, setListDocs] = useState({});
    const [errors, setErrors] = useState({
        licence_front: "",
        licence_back: "",
        licence_number: "",
    });
    useEffect(() => {
        // var url = window.location.href;
        configuration.postAPI({ url: "driver/get-driver-document", params: {user_id:Id} }).then((data) => {
          if (data.status === 200) {
            let documents = data.payload.my_documents;
            setListDocs(data.payload.my_documents)
            let fieldArr_data = [];
            let fieldData = {}
            fieldData['license_expiry_date']=data.payload.license_expiry_date;
            fieldData['id_number']=data.payload.id_number;
            documents.map((doc, index) => {
                fieldArr_data.push({
                    id: 3 + index,
                    title: doc.title,
                    name: doc.driver_document_id,
                    type: "FileInput"
                });
                fieldData[doc.driver_document_id]=doc.photo;
            })
            console.log(fieldData)
            setFields(fieldData)
            setFieldArr2([...fieldArr2, ...fieldArr_data])
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }, []);

    function handleChange(field, e) {
        if(field === 'id_number'){
            setFields({ ...fields, [field]: e.target.value })
        } else if(field === 'license_expiry_date'){
            setFields({ ...fields, [field]: e })
        } else {
            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
            setDocs({ ...docs, [field]: e.target.files[0]})
        }
    }
    function cancleImage(field, e) {
        setFields({ ...fields, [field]: '' })
        setDocs({ ...docs, [field]: null})
    }
    function validation() {
        let flag = true;
        let error = {}
        if (!fields.license_expiry_date) {
          error['license_expiry_date'] = "Please select licence front"
          flag = false
        }

        if (!fields.id_number) {
          error['id_number'] = "Please select licence back"
          flag = false
        }

        listDocs.map((sinDoc) => {  
            // console.log(fields[sinDoc.driver_document_id])
            if (!fields[sinDoc.driver_document_id] || fields[sinDoc.driver_document_id] === '') {
              error[sinDoc.driver_document_id] = `Please select ${sinDoc.title}`
              flag = false
            }
        })

        setErrors({...error })
        return flag;
    }
    const handleSubmit = () =>{
        // console.log(fields)
        // console.log(docs)
        if (validation()) {
            const formData = new FormData();
            listDocs.map((sinDoc) => { 
                if(docs[sinDoc.driver_document_id]){
                    formData.append(sinDoc.driver_document_id, docs[sinDoc.driver_document_id]);
                }
            })
            formData.append('fields', JSON.stringify({...fields, lang_code:'EN', user_id:Id}));
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }
            configuration.postFormDataAPI({url:'driver/update-document', params:formData}).then((data) => {
                if(data.status === 200){
                  // history.push('/driver')
                  return toast.success('Update successfully')
                } else if (data.status === 404) {
                  return toast.error(data.error.message)
                } else {
                  return toast.error('Something went wrong')
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
        <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                        Document Info 
                    </h2>
                    <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver documents.</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {/*<h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                        Add Document
                    </h2>*/}
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {fieldArr2.map((field) => {
                            return (
                                <div className="sm:col-span-3" key={field.id}>
                                    <Label title={field.title} />
                                    <div className="mt-1">
                                        {field.type === "TextInput" ? (
                                            <TextInput
                                                id={field.name}
                                                name={field.name}
                                                handleChange={handleChange}
                                                value={fields[field.name]}
                                                is_number={field.is_number || false}
                                                is_password={field.is_password || false}
                                            />
                                        ) : field.type === "FileInput" ? (
                                            <FileInput
                                                id={field.name}
                                                name={field.name}
                                                value={fields[field.name]}
                                                handleChange={handleChange}
                                                cancleImage={cancleImage}
                                            />
                                        ) : field.type === "DatePicker" ? (
                                            <DatePicker
                                                id={field.name}
                                                name={field.name}
                                                value={fields[field.name]}
                                                handleChange={handleChange}
                                            />
                                        ) : null}
                                    </div>
                                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                </div>
                            );
                        })}
                    </div>
                    <div className="pt-5 border_color_theme">
                        <div className="flex justify-end">
                            <BtnSaveRecord handleSubmit={handleSubmit} title="Save" path="/driverprofile" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
}

export default Summary;
