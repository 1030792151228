import PropTypes from "prop-types";
import React from "react";
import AddEditCaption from "./AddEditCaption";
import FieldSection from "./FieldSection";
import ButtonSection from "./ButtonSection";

function AddEditSection({ title, fields, fieldValue, fieldError, path, is_number, handleChange, cancleImage, handleSubmit }) {
  return (
    <div className="py-6 sm:px-6">
      <div className="relative">
        <section className="px-4" aria-labelledby="contact-heading">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <AddEditCaption title={title} />
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                  <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200">
                        <div className="pt-1">
                          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                            {fields.map((field) => {
                              return (
                                <FieldSection
                                  key={field.id}
                                  type={field.type}
                                  title={field.title}
                                  name={field.name}
                                  value={fieldValue[field.name] || ''}
                                  options={field.options || []}
                                  handleChange={handleChange}
                                  cancleImage={cancleImage}
                                  fieldError={fieldError[field.name]}
                                  is_read_only={field.is_read_only ? true : false}
                                  is_number={field.is_number ? true : false}
                                  is_full_screen={field.is_full_screen ? true : false}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <ButtonSection path={path} handleSubmit={handleSubmit}/>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

AddEditSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.func,
  // "fields.map": PropTypes.instanceOf(Array),
  path: PropTypes.string,
};

AddEditSection.defaultProps = {
  title: "",
  fields: () => {
     // do nothing.
  },
  // "fields.map": [],
  path: "",
};

export default AddEditSection;
