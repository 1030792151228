import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { contains } from 'underscore';

// List Settings
function Summary({profile_user_id}) {
    const [services, setService] = useState([]);
    const Id = profile_user_id;
    const [selectedServices, setSelectedService] = useState([]);
    useEffect(() => {
        getData(Id);
        // console.log(configuration.baseURL);
    }, []);
    function getData(Id) {
        configuration.getAPI({url:'service/get-service', params:{status:'active'}, backUrl:true}).then((data) => {
          if(data.status === 200){
            setService(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        configuration.getAPI({url:'passenger/detail', params:{user_id:Id}, backUrl:true}).then((data) => {
          if(data.status === 200){
            setSelectedService(data.payload.service_ids);
          }
        }).catch(error => {
          return toast.error(error.message)
        });
    }
    function handleSelect(serviceId, isSelected) {
        console.log(serviceId);
        configuration.postAPI({ url: 'driver/update-service', params: {service_id:serviceId, type:(isSelected)?'remove':'add', user_id:Id} }).then((data) => {
            if (data.status === 200) {
              getData(Id)
              return toast.success('Update successfully')
            } else if (data.status === 404) {
              return toast.error(data.error.message)
            } else {
              return toast.error('Something went wrong')
            }
        }).catch(error => {
            return toast.error(error.message)
        });
    }
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Services
                        </h2>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver services.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="services flex space-x-6 items-center">
                            {services.map((service) => {
                                const isSelected = contains(selectedServices, service.service_id);
                                const mainClass = (isSelected)?"border-gray-200 border px-3 py-3 text-center rounded-md w-28 cursor-pointer bg-indigo-600":"border-gray-200 border px-3 py-3 text-center rounded-md w-28 cursor-pointer";
                                const subClass = (isSelected)?"text-basefont-medium text-white pt-2":"text-basefont-medium pt-2 text-gray-600";
                                return (<div onClick={()=>handleSelect(service.service_id, isSelected)} className={mainClass}>
                                    <div className="">
                                        <p as="span" className="block text-basefont-medium text-gray-900">
                                            <img className="mx-auto" src={service.icon} alt={service.title}/>
                                        </p>
                                        <p as="span" className={subClass}>
                                            {service.title}
                                        </p>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
