import { Link } from "react-router-dom";
import labels from "../../constants/ForgotPassword";
import PropTypes from "prop-types";
import configuration from '../../config';
import React, { useState } from "react";
import { toast } from 'react-toastify';

function View({ history }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  function validation() {
    let flag = true;
    let error = {}
    if (!fields.email) {
      error['email'] = "Please enter email"
      flag = false
      toast.error("Please enter email");
    }
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    // console.log("fields");
    // console.log(fields);
    if (validation()) {
      configuration.postAPI({ url: 'auth/forgot-password', params: fields }).then((data) => {
        // console.log(data)
        if (data.status === 200) {
          return toast.success("We have sent you link on your email address")
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }

  return (
    <div className="sm:h-screen h-full grid grid-cols-1 lg:grid-cols-6">

      <div className="hidden lg:block relative flex-1 col-span-4">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="./image/forgot.png"
          alt=""
        />
      </div>
      <div className="flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 xl:px-8 col-span-2">
        <div className="mx-auto w-full max-w-lg ">
          <div>
            <img
              className="h-14 w-auto"
              src="./image/logo.png"
              alt="Workflow"
            />
            <h2 className="mt-10 text-3xl font-extrabold text-gray-900">{labels.forgotPassword}</h2>

          </div>

          <div>

            <div className="mt-6">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block font-medium text-gray-700 text-base text_theme"
                  >
                    {labels.emailAddress}
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={(e) => setFields({ ...fields, email: e.target.value })}
                      value={fields.email}
                      autoComplete="email"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 input_theme"
                    />
                  </div>
                  {errors.email ? <label title='email' fieldError={errors.email} /> : null}
                </div>

                <div>
                  <button
                    type="button"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleSubmit}
                  >
                    {labels.resetPassword}
                  </button>
                </div>
              </form>
              <div className="text-center mt-6 text-base text_theme font-medium">
                {labels.justRemembered}{" "}
                <Link className="text-indigo-600 hover:text-indigo-500" to="/">
                  {labels.signIn}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 page_bg">
    //   <div className="sm:mx-auto sm:w-full sm:max-w-md">
    //     <img
    //       className="mx-auto h-20 w-auto"
    //       src="logo.png"
    //       alt="Workflow"
    //     />
    //     <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 text_theme">
    //       {labels.forgotPassword}
    //     </h2>
    //   </div>

    //   <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    //     <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 box_bg">
    //       <form className="space-y-6">
    //         <div>
    //           <label
    //             htmlFor="email"
    //             className="block font-medium text-gray-700 text-base text_theme"
    //           >
    //             {labels.emailAddress}
    //           </label>
    //           <div className="mt-1">
    //             <input
    //               id="email"
    //               name="email"
    //               type="email"
    //               onChange={(e)=>setFields({...fields, email: e.target.value})}
    //               value={fields.email}
    //               autoComplete="email"
    //               className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 input_theme"
    //             />
    //           </div>
    //           {errors.email ? <label title='email' fieldError={errors.email} /> : null}
    //         </div>

    //         <div>
    //           <button
    //             type="button"
    //             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    //             onClick={handleSubmit}
    //           >
    //             {labels.resetPassword}
    //           </button>
    //         </div>
    //       </form>
    //       <div className="text-center mt-6 text-base text_theme font-medium">
    //         {labels.justRemembered}{" "}
    //         <Link className="text-indigo-600 hover:text-indigo-500" to="/">
    //           {labels.signIn}
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

View.propTypes = {
  history: PropTypes.func,
  // "history.push": PropTypes.func,
};

View.defaultProps = {
  history: () => {
    // do nothing.
  },
  // "history.push": () => {
  //    // do nothing.
  // },
};

export default View;
