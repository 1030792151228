import PropTypes from "prop-types";
import React from "react";

function Dropdown({ name, status, handleChange, value, title }) {
  // let title = name.split('_').join(' ');
  // title = title.replace(" id", "");
  // title = title.charAt(0).toUpperCase() + title.slice(1);
  return (
    <select
      id={name}
      name={name}
      value={value}
      onChange={(e)=>handleChange(name, e)}
      autoComplete="country"
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
    >
      <option value="">---Select {title}---</option>
      {status.map((opt) => {
        return (
          <option value={opt.value} key={opt.id}>
            {opt.title}
          </option>
        );
      })}
    </select>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string,
  status: PropTypes.func,
  // "status.map": PropTypes.instanceOf(Array)
};

Dropdown.defaultProps = {
  name: "",
  status: () => {
    // do nothing.
  },
  // "status.map": [],
};

export default Dropdown;
