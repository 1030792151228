import { HomeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import labels from "../constants/Breadcrumb";

// For Navigate
function Breadcrumb({ module }) {
  let breadcrumbArr = [];
  switch (module) {
    case "Dashboard":
      breadcrumbArr = [{ name: "Dashboard", href: "#", current: true }];
      break;
    
    case "EditDispatcher":
      breadcrumbArr = [
        { name: "Profile", href: "#", current: true },
      ];
      break;
    case "EditCompany":
      breadcrumbArr = [
        { name: "Profile", href: "#", current: true },
      ];
      break;
    case "EditPartner":
      breadcrumbArr = [
        { name: "Profile", href: "#", current: true },
      ];
      break;
    case "EditBilling":
      breadcrumbArr = [
        { name: "Profile", href: "#", current: true },
      ];
      break;
    case "Driver":
      breadcrumbArr = [
        { name: "Driver", href: "#", current: true },
      ];
      break;
    case "AddDriver":
        breadcrumbArr = [
          { name: "Driver", href: "/driver", current: false },
          { name: "Add Driver", href: "#", current: true },
        ];
      break;
    case "EditDriver":
        breadcrumbArr = [
          { name: "Driver", href: "/driver", current: false },
          { name: "Edit Driver", href: "#", current: true },
        ];
      break;
    case "DriverProfile":
        breadcrumbArr = [
          { name: "Driver", href: "/driver", current: false },
          { name: "Driver Profile", href: "#", current: true },
        ];
      break;
    case "Trips":
      breadcrumbArr = [
        { name: "Trips", href: "#", current: true },
      ];
      break;
    case "CreateRequest":
      breadcrumbArr = [
        { name: "Create Request", href: "#", current: true },
      ];
      break;
    case "LoginLogs":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Login Log", href: "#", current: true },
      ];
      break;
    default:
    case "TripsDetail":
      breadcrumbArr = [
        { name: "Trips", href: "/trips", current: false },
        { name: "Trips Detail", href: "#", current: true },
      ];
      break;
    case "TripsInvoice":
      breadcrumbArr = [
        { name: "Trips", href: "/trips", current: false },
        { name: "Invoice", href: "#", current: true },
      ];
      break;
    case "SalesRevenue":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Sales Revenue", href: "#", current: true },
      ];
      break;
    case "NetProfits":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Net Profits", href: "#", current: true },
      ];
      break;
  }

  return (
    <nav
      className="bg-white border-b border-gray-200 flex xl-breadcrumb breadcrumb_theme"
      aria-label="Breadcrumb"
    >
      <ol
        // eslint-disable-next-line jsx-a11y/aria-role
        role="listData"
        className="max-w-screen-xl w-full px-4 flex space-x-4 sm:px-6 lg:px-8"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link
              to="/dashboard"
              className="text-gray-700 hover:text-indigo-600 icon_theme"
            >
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{labels.home}</span>
            </Link>
          </div>
        </li>
        {breadcrumbArr.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200 breadcrumb_separator"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              {page.current ? (
                <span
                  className="ml-4 text-base font-medium text-gray-400"
                  aria-current={page.current ? "page" : null}
                >
                  {page.name}
                </span>
              ) : (
                <Link
                  to={page.href}
                  className="ml-4 text-base font-medium text-gray-700 hover:text-indigo-600 icon_theme"
                  aria-current={page.current ? "page" : null}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

Breadcrumb.propTypes = {
  module: PropTypes.string,
};

Breadcrumb.defaultProps = {
  module: "",
};

export default Breadcrumb;
