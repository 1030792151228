import {
  BookmarkAltIcon,
  ChartBarIcon,
  CogIcon,
  LogoutIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  GlobeAltIcon,
  MenuIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Menu, Popover, Transition } from "@headlessui/react";
import { Link, useHistory } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { reactLocalStorage } from 'reactjs-localstorage';
import configuration from '../config';
import common from "../constants/Common";
var jwt = require('jsonwebtoken');

// Multiple classes
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const solutions = [
  {
    name: "Analytics",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: ChartBarIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: CursorClickIcon,
  },
  {
    name: "Security",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: ShieldCheckIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: ViewGridIcon,
  },
];
const resources = [
  { name: "Community", href: "#", icon: UserGroupIcon },
  { name: "Partners", href: "#", icon: GlobeAltIcon },
  { name: "Guides", href: "#", icon: BookmarkAltIcon },
  { name: "Webinars", href: "#", icon: DesktopComputerIcon },
];
let user_id = ""
let user_type = ""
// App header
function Header() {
  const [user, setUser] = useState({});
  const [activeTab, setActiveTab] = useState("dashboard");
  const history = useHistory();
  function handleSignOut() {
    configuration.postAPI({ url: 'auth/sign-out', params: { loginlog_id: reactLocalStorage.get('loginlog_id') } }).then((data) => {
      // console.log(data)
      reactLocalStorage.set('token', "");
      reactLocalStorage.set('loginlog_id', "");
      reactLocalStorage.set('user_id', "");
      reactLocalStorage.set('user_type', "");
      reactLocalStorage.set('email', "");
      history.push("/")
      // if(data.status === 200){
      //   // setData(data.payload)
      // } else if (data.error) {
      //   return toast.error(data.error.message)
      // } else {
      //   return toast.error('Something went wrong')
      // }
    }).catch(error => {
      // console.log(error)
      // return toast.error(error.message)
    });
  }
  useEffect(() => {
    var token = reactLocalStorage.get('token');
    jwt.verify(token, configuration.appName, function (err, decoded) {
      // console.log(decoded)
      if (err) {
        decoded = null;
        history.push("/")
      }
      if (decoded) {
        // console.log(decoded)
        user_id = decoded.user_id;
        user_type = decoded.user_type;
        setUser({ name: decoded.name, user_type: decoded.user_type, email: decoded.email, imageUrl: (decoded.photo !== "") ? decoded.photo : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" })
      }
    });
    let text = window.location.href;
    if (text.includes("driver")) {
      setActiveTab("driver")
    } else if (text.includes("trip")) {
      setActiveTab("trip")
    } else if (text.includes("createrequest")) {
      setActiveTab("createrequest")
    } else if (text.includes("sales")) {
      setActiveTab("report")
    } else if (text.includes("dashboard")) {
      setActiveTab("dashboard")
    } else {
      setActiveTab("")
    }
  }, []);

  return (
    <>
      <Popover className="relative bg-white shadow-sm bg-gray-900 header_bg">
        {({ open }) => (
          <>
            <div className="flex justify-between items-center px-4 py-1 sm:px-6 md:justify-start md:space-x-10">
              <div>
                <Link to="#" className="flex">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src={common.logo_url}
                    alt=""
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                <Popover.Group
                  as="nav"
                  className="flex space-x-10 xl-menu-style"
                >
                  <Link
                    to="/dashboard"
                    onClick={() => setActiveTab('dashboard')}
                    className={(activeTab === 'dashboard') ? "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700" : "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                  >
                    Dashboard
                  </Link>
                  {(user_type === 'company')?
                    <Link
                      to="/driver"
                      onClick={() => setActiveTab('driver')}
                      className={(activeTab === 'driver') ? "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700" : "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                    // className="text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"
                    >
                      My Drivers
                    </Link>:''
                  }
                  <Link
                    to="/create-request"
                    onClick={() => setActiveTab('createrequest')}
                    className={(activeTab === 'createrequest') ? "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700" : "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                    >
                    Create Request
                  </Link>
                  <Link
                    to="/trips"
                    onClick={() => setActiveTab('trip')}
                    className={(activeTab === 'trip') ? "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700" : "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                  // className="text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"
                  >
                    My Trips
                  </Link>
                  {(user_type === 'company' || user_type === 'dispatcher')?
                    <Link
                      to="/tracker"
                      target="_blank"
                      onClick={() => setActiveTab('tracker')}
                      className={(activeTab === 'tracker') ? "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700" : "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                      >
                      Live Trips
                    </Link>:''
                  }
                  <Menu as="div" className="ml-4 relative flex-shrink-0">
                    <div className="relative">
                      <Menu.Button

                        className={(activeTab === 'report') ? "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700" : "text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                        // className="inline text-base font-semibold text-black hover:text-black hover:bg-gray-700 px-3 py-2 rounded-md text_theme">
                        >
                        <span>My Reports</span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 inline"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-20  transform -translate-x-1/1 mt-3 px-2 w-screen max-w-xs sm:px-0">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid  bg-white px-3 gap-5 py-3 ">
                              <Link
                                to="/sales"
                                onClick={() => setActiveTab('report')}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900 hover:text-black">
                                    Sales
                                  </p>
                                </div>
                              </Link>
                              <Link
                                to="/sales"
                                onClick={() => setActiveTab('report')}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900 hover:text-black">
                                    Earning
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </div>
                  </Menu>
                </Popover.Group>
                <Menu as="div" className="relative">
                  {({ open }) => (
                    <>
                      <Menu.Button className="group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none  text-gray-900">
                        <div className="flex-shrink-0 inline-flex border-gray-200 ml-4">
                          <a href="#" className="flex-shrink-0 w-full group block">
                            <div className="flex items-center">
                              <div>
                                <img
                                  className="inline-block h-9 w-9 rounded-full"
                                  src={user.imageUrl}
                                  alt=""
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-lg font-medium text-white">{user.name}</p>
                                <p className="text-xs font-normal text-left text-white capitalize">{user.user_type}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 right-0 transform  mt-3 px-2 w-max	 sm:px-0">
                          <div className="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-2 sm:p-4">
                              <Link
                                // to={`/user/edit/${user_id}`}
                                to={{
                                  pathname: `/${user_type}/edit`,
                                  state:user_id
                                }}
                                onClick={() => setActiveTab('')}
                                className="text-justify text-base font-medium text-indigo-700 hover:text-gray-900"
                              >
                                <CogIcon className="h-6 w-6 inline mr-2" aria-hidden="true" />My Settings
                              </Link>
                              <button
                                type="button"
                                onClick={handleSignOut}
                                className="text-justify focus:outline-none text-base font-medium text-indigo-700 hover:text-gray-900"
                              >
                                <LogoutIcon className="h-6 w-6 inline mr-2" aria-hidden="true" />Sign Out
                              </button>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-6">
                        {solutions.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                          >
                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white">
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4 text-base font-medium text-gray-900">
                              {item.name}
                            </div>
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5">
                    <div className="grid grid-cols-2 gap-4">
                      <Link
                        to="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Pricing
                      </Link>

                      <Link
                        to="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Docs
                      </Link>

                      <Link
                        to="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Enterprise
                      </Link>
                      {resources.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    <div className="mt-6">
                      <Link
                        to="#"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                      >
                        Sign up
                      </Link>
                      <p className="mt-6 text-center text-base font-medium text-gray-500">
                        Existing customer?{" "}
                        <Link
                          to="#"
                          className="text-indigo-600 hover:text-indigo-500"
                        >
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}

export default Header;
