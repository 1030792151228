import React, { Fragment, useState, useEffect } from "react";
import { CurrencyRupeeIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import configuration from '../../../config';
import DataTable from "../../../components/MDataTable";
import Label from "../../../components/Form/Label";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
// List Settings
function Summary({profile_user_id}) {
    const Id = profile_user_id;
    const [open, setOpen] = useState(false)
    const [amount, setAmount] = useState('')
    const [amountErr, setAmountErr] = useState('')
    const [data, setData] = useState([]);
    const [walletAmount, setWalletAmount] = useState(0);
    useEffect(() => {
        getLog()
        // console.log(configuration.baseURL);
    }, []);

    function getLog(){
        configuration.getAPI({url:'passenger/detail', params:{user_id:Id}, backUrl:true}).then((data) => {
          if(data.status === 200){
            setWalletAmount(data.payload.wallet_balance)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        configuration.getAPI({url:'common/list-wallet-logs', params:{user_id:Id}}).then((data) => {
          if(data.status === 200){
            setData(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
    }

    function handleAddMoney(){
        if (!amount) {
          setAmountErr("Please enter amount")
        } else if (Number(amount) <= 0){
          setAmountErr("Please enter valid amount")
        } else {
            console.log(amount)
            configuration
                .postAPI({ url: "common/refill", params: {user_id:Id, refill_amount:amount} })
                .then((data) => {
                  if (data.status === 200) {
                    getLog()
                    setOpen(false) 
                    setAmount('') 
                    return toast.success("Add wallet money successfully");
                    // history.push({ pathname: "/language", state: { is_added: true } });
                  } else if (data.error) {
                    return toast.error(data.error.message);
                  } else {
                    return toast.error("Something went wrong");
                  }
                })
                .catch((error) => {
                  return toast.error(error.message);
                });
        }
    }
    return (
       <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Wallet
                        </h2>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver wallet.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-2">
                            <li key="Available Balance" className="col-span-1 flex shadow-sm rounded-md">
                                <div
                                    className={classNames(
                                        'bg-gray-100',
                                        'flex-shrink-0 flex items-center justify-center w-16 text-white border text-basefont-medium rounded-l-md'
                                    )}
                                >
                                    <img className="h-5 w-5" src="../../image/coin.png" alt="" />
                                </div>
                                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                                    <div className="flex-1 px-4 py-2 text-basetruncate">
                                        <p className="text-gray-600 font-medium hover:text-gray-600">
                                            Available Balance
                                        </p>
                                        <p className="text-gray-900 text-lg">{(walletAmount)}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <button
                            type="button"
                            className="mb-5 mt-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                            onClick={()=>{
                                setOpen(true) 
                                setAmount('') 
                                setAmountErr('')
                                }}
                        >
                            Add Amount
                        </button>
                        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                            {data.map((person) => (
                                <li key={person.wallet_id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        <div className="flex-1 truncate">
                                            {/*<span className="text-indigo-600 text-base font-medium  ">
                                                {person.wallet_id}
                                            </span>*/}
                                            <div className="flex items-center space-x-3">
                                                <h3 className="text-gray-900 text-lg font-bold truncate pt-2">#{person.wallet_id}</h3>
                                            </div>
                                            <p className="mt-1 text-gray-500 text-basetruncate">{person.created_at}</p>
                                        </div>
                                        <h3 className="text-gray-900 text-lg font-bold truncate">{person.amount}</h3>
                                    </div>

                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={()=>setOpen(true)}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 pb-5">
                          Add Money
                        </Dialog.Title>
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <CurrencyRupeeIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <input
                              type= "number"
                              value = {amount}
                              onChange={(e)=>setAmount(e.target.value)}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
                            />
                            {(amountErr && amountErr !== '') ? <Label title='amount' fieldError={amountErr} /> : null}
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={handleAddMoney}
                        >
                          Add Money
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={()=>{
                            setOpen(false) 
                            setAmount('') 
                            setAmountErr('')
                            }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
        </div>
    );
}

export default Summary;
