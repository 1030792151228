import PropTypes from "prop-types";
import React from "react";
import PlacesAutocomplete from 'react-places-autocomplete';

// For Input Element
function Autocomplete({ id, name, handleChange, value, handleSelect }) {
  return (
    <>
      <PlacesAutocomplete
        name={name}
        id={id}
        value={value}
        onChange={(e)=>handleChange(name, e)}
        onSelect={(e)=>handleSelect(name, e)}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
        >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (

          <div className="Demo__search-bar-container">
              <div className="Demo__search-input-container">
                  <input 
                      {...getInputProps({
                          placeholder: 'Enter address',
                          className: 'location-search-input',
                      })} 
                      className="form-control"
                  />
                  {/*this.state.address.length > 0 && (
                      <button className="Demo__clear-button" onClick={this.handleCloseClick.bind(this)}>x</button>
                  )*/}
              </div>
              <div className="Demo__autocomplete-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                      const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                      const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                          <div
                              {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                              })}
                          >
                              <strong>
                                  {suggestion.formattedSuggestion.mainText}
                              </strong>{' '}
                              <small>
                                  {suggestion.formattedSuggestion.secondaryText}
                              </small>
                          </div>
                        );
                  })}
              </div>
          </div>
      )}
      </PlacesAutocomplete>
    </>
  );
}

Autocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

Autocomplete.defaultProps = {
  id: "",
  name: "",
};

export default Autocomplete;
