import Dropdown from "./Form/Dropdown";
// import Editor from "./Form/Editor";
// import TrixEditor from "./Form/TrixEditor";
import TinyEditor from "./Form/TinyEditor";
import Label from "./Form/Label";
import MultiDropdown from "./Form/MultiDropdown";
import MultiSelect from "./Form/MultiSelect";
import PropTypes from "prop-types";
import React from "react";
import TextArea from "./Form/TextArea";
import FileInput from "./Form/FileInput";
import TextInput from "./Form/TextInput";
import SwitchInput from "./Form/SwitchInput";
import CopyTextInput from "./Form/CopyTextInput";
import Map from "./Form/Map";
import DatePicker from "./Form/DatePicker";
// Form Field Section
function FieldSection({ title, type, name, value, options, body, handleChange, is_read_only, is_number, is_full_screen, fieldError, cancleImage }) {
  return (
    <div className={(is_full_screen)?"sm:col-span-2":""}>
      <Label title={title} />
      <div className="mt-1">
        {type === "TextInput" ? (
          <TextInput id={name} name={name} value={value} handleChange={handleChange} is_read_only={is_read_only} is_number={is_number} />
        ) :type === "CopyInput" ? (
          <CopyTextInput id={name} name={name} value={value}/>
        ) :type === "FileInput" ? (
          <FileInput id={name} name={name} value={value} handleChange={handleChange} cancleImage={cancleImage}/>
        ) :type === "SwitchInput" ? (
          <SwitchInput id={name} name={name} value={value} handleChange={handleChange}/>
        ) : type === "Dropdown" ? (
          <Dropdown id={name} name={name} title={title} value={value} status={options} handleChange={handleChange} />
        ) : type === "MultiDropdown" ? (
          <MultiDropdown id={name} name={name} value={value} options={options} handleChange={handleChange}/>
        ) : type === "MultiSelect" ? (
          <MultiSelect id={name} name={name} value={value} options={options} handleChange={handleChange}/>
        ) : type === "TextArea" ? (
          <TextArea id={name} name={name} value={value} handleChange={handleChange}/>
        ): type === "DatePicker" ? (
          <DatePicker id={name} name={name} value={value} handleChange={handleChange}/>
        ) : type === "GoogleMap" ? (
          <Map id={name} name={name} value={value} handleChange={handleChange}/>
        ) : type === "Editor" ? (
          <TinyEditor className="" name={name} value={value} handleChange={handleChange}/>
        ) : null}
      </div>
      {fieldError ? <Label title={title} fieldError={fieldError} /> : null}
    </div>
  );
}

FieldSection.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  body: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

FieldSection.defaultProps = {
  title: "",
  type: "",
  name: "",
  options: [],
  body: "",
  handleChange: function () { },
  handleSubmit: function () { },
};

export default FieldSection;
