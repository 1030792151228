import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import labels from "../../constants/Dashboard";
import { InformationCircleIcon } from '@heroicons/react/outline'
import configuration from "../../config";
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import { toast } from "react-toastify";
import { reactLocalStorage } from 'reactjs-localstorage';


import { Line, Bar } from "react-chartjs-2";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const Tabs = ({ color }) => {
  const master_user_type = reactLocalStorage.get('user_type')
  const master_user_id = reactLocalStorage.get('user_id')
  const [years, setYears] = useState([]);
  const [salesYear, setSalesYear] = useState('');
  const [profitYear, setProfitYear] = useState('');
  const [Sales, setSales] = useState([]);
  const [Profit, setProfit] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [driverLabel, setDriverLabel] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyLabel, setCompanyLabel] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [partnerLabel, setPartnerLabel] = useState([]);
  const [dispatcherData, setDispatcherData] = useState([]);
  const [dispatcherLabel, setDispatcherLabel] = useState([]);

  // For Dashboard

  function totalSales(type, year){
    configuration.getAPI({ url: 'dashboard/total-sales', params: {type, year} }).then((data) => {
      if(type === 'sales'){
        setSales(data.payload || []);
      }
      if(type === 'commission'){
        setProfit(data.payload || []);
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  function topPerformings(type){
    configuration.getAPI({ url: 'dashboard/top-performings', params: {type} }).then((data) => {
      if(type === 'driver'){
        setDriverData(data.payload.data || []);
        setDriverLabel(data.payload.labels || []);
      }
      if(type === 'partner'){
        setPartnerData(data.payload.data || []);
        setPartnerLabel(data.payload.labels || []);
      }
      if(type === 'company'){
        setCompanyData(data.payload.data || []);
        setCompanyLabel(data.payload.labels || []);
      }
      if(type === 'dispatcher'){
        setDispatcherData(data.payload.data || []);
        setDispatcherLabel(data.payload.labels || []);
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  useEffect(() => {
    setSalesYear(new Date().getFullYear())
    setProfitYear(new Date().getFullYear())
    const startYear = 2022;
    const endYear = new Date().getFullYear();
    for(let i = startYear; i<=endYear; i++){
        years.push(i);
    }
    setYears(years)
    if(master_user_type === 'billing'){
      topPerformings('driver')
      topPerformings('partner')
      topPerformings('company')
      topPerformings('dispatcher')
    } else if(master_user_type === 'company'){
      totalSales('sales', new Date().getFullYear())
      totalSales('commission', new Date().getFullYear())
      topPerformings('driver')
    } else {
      totalSales('sales', new Date().getFullYear())
      totalSales('commission', new Date().getFullYear())
    }
  }, []);

  function handleChange(type, e){
    if(type === 'sales'){
      setSalesYear(e.target.value);
    } else {
      setProfitYear(e.target.value)
    }
    totalSales(type, e.target.value);
  }

  return (
    <>
      <Breadcrumb module={labels.dashboard} />
      {(master_user_type !== 'billing')?
        <div className=" px-3 py-3 grid md:grid-cols-2 gap-6">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Sales Revenue
              </h2>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div className="grid grid-cols-2 mb-5">
                <select
                  id="country10"
                  name="country10"
                  autoComplete="country10-name"
                  onChange={(e)=>handleChange('sales', e)}
                  value={salesYear}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                >
                  {
                    years.map((e, key) => {
                      return <option key={key} value={e}>{e}</option>;
                    })
                  }
                </select>
              </div>
              <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">This chart shows total trips revenue by month & year wise</p>
                  </div>
                </div>
              </div>
              <div className=" mt-5  chart-wrapper">
                <Bar data={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                  datasets: [
                    {
                      label: "Sales Revenue (₹)",
                      data: Sales,
                      fill: false,
                      backgroundColor: "rgba(255, 175, 38, 1)",
                    }
                  ]
                }} />
                <p className="text-center w-full pt-4 ">Month</p>
              </div>
            </div>
          </div>
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Profit
              </h2>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div className="grid grid-cols-2 mb-5">
                <select
                  id="country10"
                  name="country10"
                  onChange={(e)=>handleChange('commission', e)}
                  value={profitYear}
                  autoComplete="country10-name"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                >
                  {
                    years.map((e, key) => {
                      return <option key={key} value={e}>{e}</option>;
                    })
                  }
                </select>
              </div>
              <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">Profit Shows XLCAB Earning ( Deducting from Trip Amount , Driver Earning , GST , etc )</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 chart-wrapper">
                <Line data={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                  datasets: [
                    {
                      label: "Profit (₹)",
                      data: Profit,
                      fill: true,
                      backgroundColor: "rgb(255, 175, 38, 0.2)",
                      borderColor: "rgba(255, 175, 38, 1)"
                    }
                  ]
                }} />
                <p className="text-center w-full pt-4 ">Month</p>
              </div>
            </div>
          </div>
        </div>:''
      }
      {(master_user_type === 'company' || master_user_type === 'billing')?
        <div className={`px-3 py-3 grid md:grid-cols-${(master_user_type === 'billing')?'2':'1'} gap-6`}>
          <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  Top Performing Drivers
                </h2>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <div className="shadow-lg mt-5 mb-5 chart-wrapper">
                  <Line data={{
                    labels: driverLabel,
                    datasets: [
                      {
                        label: "Driver",
                        data: driverData,
                        fill: true,
                        backgroundColor: "rgb(234, 179, 8,0.2)",
                        borderColor: "rgba(234, 179, 8,1)"
                      }
                    ]
                  }} />
                </div>
              </div>
          </div>
          {(master_user_type === 'billing')?
            <>
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Top Performing Companies
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="shadow-lg mt-5 mb-5 chart-wrapper">
                    <Line data={{
                      labels: companyLabel,
                      datasets: [
                        {
                          label: "Company",
                          data: companyData,
                          fill: true,
                          backgroundColor: "rgb(14, 165, 233,0.2)",
                          borderColor: "rgba(14, 165, 233,1)"
                        }
                      ]
                    }} />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Top Performing Dispatchers
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="shadow-lg mt-5 mb-5 chart-wrapper">
                    <Line data={{
                      labels: dispatcherLabel,
                      datasets: [
                        {
                          label: "Dispatcher",
                          data: dispatcherData,
                          fill: true,
                          backgroundColor: "rgb(22, 163, 74,0.2)",
                          borderColor: "rgba(22, 163, 74,1)"
                        }
                      ]
                    }} />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Top Performing Partners
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="shadow-lg mt-5 mb-5 chart-wrapper">
                    <Line data={{
                      labels: partnerLabel,
                      datasets: [
                        {
                          label: "Partner",
                          data: partnerData,
                          fill: true,
                          backgroundColor: "rgb(107, 33, 168,0.2)",
                          borderColor: "rgba(107, 33, 168,1)"
                        }
                      ]
                    }} />
                  </div>
                </div>
              </div>
            </>:''
          }
        </div>:''
      }
    </>
  );

}

export default function TabsRender() {
  return (
    <>
      <Tabs color="white" />
    </>
  );
}