import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/Form/Dropdown";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import FileInput from "../../../components/Form/FileInput";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import { TrashIcon, PencilIcon} from "@heroicons/react/outline";

import configuration from '../../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// List Settings
function Summary({profile_user_id}) {
    const Id = profile_user_id;
    const [editFlag, setEditFlag] = useState(false);
    const [vehicleType, setVehicleType] = useState([]);
    const [fields, setFields] = useState({});
    const [vehicleList, setVehicleList] = useState([]);
    const [listDocs, setListDocs] = useState([]);
    const [fieldArr2, setFieldArr2] = useState([{ id: 0, title: "Plate number", name: "plate_no", type: "TextInput"}]);
    const [docs, setDocs] = useState([]);
    const [errors, setErrors] = useState({
    });
    function getVehicle(Id){
        configuration.getAPI({ url: 'driver/get-vehicle-list', params: { user_id:Id } }).then((data) => {
          if (data.status === 200) {
            setVehicleList(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
    }
    useEffect(() => {
        configuration.getAPI({url:'vehicle/list', params:{status:'active'}, backUrl:true}).then((data1) => {
          // console.log(data1)
          if(data1.status === 200){
            setVehicleType(data1.payload)
            let listData = [];
            each(data1.payload, element=>{
                listData.push({ 'title': `${element.vehicle_make} -- ${element.vehicle_model} -- ${element.year}`, 'value': element.vehicle_id, 'id': element.vehicle_id })
            })
            let fieldArr_data = [];
            const orderNoObj = {
              id: 1,
              title: "Vehicle Type",
              name: "vehicle_id",
              type: "Dropdown",
              options: listData,
            }
            fieldArr_data.push(orderNoObj);
            configuration.getAPI({ url: 'driver/get-vehicle-document', params: { user_id:Id } }).then((data) => {
              if (data.status === 200) {
                // setVehicleList(data.payload)
                let documents = data.payload;
                setListDocs(data.payload)
                documents.map((doc, index) => {
                    fieldArr_data.push({
                        id: 3 + index,
                        title: doc.title,
                        name: doc.vehicle_document_id,
                        type: "FileInput"
                    });
                })
                setFieldArr2([...fieldArr2, ...fieldArr_data])
              }
            }).catch(error => {
              return toast.error(error.message)
            });
          }
        }).catch(error => {
          // console.log(error)
          return toast.error(error.message)
        });
        getVehicle(Id);
        // console.log(configuration.baseURL);
      }, []);
    function handleChange(field, e) {
        if(field === 'vehicle_id' || field === 'plate_no' || field === 'vehicle_mobility_ids'){
            setFields({ ...fields, [field]: e.target.value })
        } else {
            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
            setDocs({ ...docs, [field]: e.target.files[0]})
        }
    }
    function cancleImage(field, e) {
        setFields({ ...fields, [field]: '' })
        setDocs({ ...docs, [field]: null})
    }

    function handleValidation() {
        let flag = true;
        let error = {}

        if (!fields.vehicle_id) {
          error['vehicle_id'] = "Please select vehicle"
          flag = false
        }

        if (!fields.plate_no) {
          error['plate_no'] = "Please enter plate number"
          flag = false
        }
        listDocs.map((sinDoc) => {  
            // console.log(fields[sinDoc.vehicle_document_id])
            if (!fields[sinDoc.vehicle_document_id] || fields[sinDoc.vehicle_document_id] === '') {
              error[sinDoc.vehicle_document_id] = `Please select ${sinDoc.title}`
              flag = false
            }
        })
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () =>{
        if (handleValidation()) {
          const formData = new FormData();
          listDocs.map((sinDoc) => { 
            if(docs[sinDoc.vehicle_document_id]){
                formData.append(sinDoc.vehicle_document_id, docs[sinDoc.vehicle_document_id]);
            }
          })
          formData.append('fields', JSON.stringify({...fields, lang_code:'EN', user_id:Id}));
          const apiURL = editFlag?'driver/update-vehicle':'driver/add-vehicle';

          configuration.postFormDataAPI({url:apiURL, params:formData}).then((data) => {
            if(data.status === 200){
                // setPhoto(null);
                setFields({
                    vehicle_id:'',
                    plate_no:'',
                })
                setDocs([]);
                setEditFlag(false)
                getVehicle(Id);
              return toast.success((editFlag)?"Vehicle updated successfully":"Vehicle added successfully");
            } else if (data.status === 404) {
              return toast.error(data.error.message)
            } else {
              return toast.error('Something went wrong')
            }
          }).catch(error => {
            return toast.error(error.message)
          });
        }
    }
    function handleDelete(driver_vehicle_id) {
        configuration
        .postAPI({ url: "driver/remove-vehicle", params: {user_id:Id, driver_vehicle_id:driver_vehicle_id} })
        .then((data) => {
          if (data.status === 200) {
            getVehicle(Id);
            return toast.error("Vehicle deleted successfully");
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
    function handleEdit(driver_vehicle_id){
        configuration.getAPI({ url: 'driver/get-vehicle-list', params: { user_id:Id, driver_vehicle_id:driver_vehicle_id } }).then((data) => {
          if (data.status === 200) {
            let fieldData = {}
            each(data.payload.vehicle_documents, single=>{
                fieldData[single.vehicle_document_id]=single.photo;
            })
            setFields({...data.payload, ...fieldData})
            setErrors({})
            setEditFlag(true)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
    }
    function handleClear(){
        setEditFlag(false)
        setFields({
            vehicle_id:'',
            plate_no:'',
        })
        setDocs([]);
        setErrors({})
        getVehicle(Id);
    }
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Vehicle Info
                        </h2>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver vehicles details.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                            Add Vehicle
                        </h2>
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            {fieldArr2.map((field) => {
                                return (
                                    <div className="sm:col-span-3" key={field.id}>
                                        <Label title={field.title} />
                                        <div className="mt-1">
                                            {field.type === "TextInput" ? (
                                                <TextInput
                                                    id={field.name}
                                                    name={field.name}
                                                    value={fields[field.name]}
                                                    handleChange={handleChange}
                                                    is_number={field.is_number || false}
                                                    is_password={field.is_password || false}
                                                />
                                            ) : field.type === "Dropdown" ? (
                                                <Dropdown
                                                    id={field.name}
                                                    value={fields[field.name]}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    status={field.options || []}
                                                />
                                            ) : field.type === "FileInput" ? (
                                                <FileInput
                                                    id={field.name}
                                                    value={fields[field.name]}
                                                    handleChange={handleChange}
                                                    cancleImage={cancleImage}
                                                    name={field.name}
                                                />
                                            ) : null}
                                        </div>
                                        {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="pt-5 border_color_theme">
                            <div className="flex justify-end">
                                <button
                                  type="button"
                                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                  onClick={() => handleClear()}
                                >
                                  Clear
                                </button>
                                <BtnSaveRecord title={(editFlag)?"Update":"Save"} handleSubmit={handleSubmit} path="/driverprofile" />
                            </div>
                        </div>
                        
                        <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                            Vehicle
                        </h2>

                        <ul role="list" className="grid grid-cols-2 gap-6">
                            {vehicleList.map((product) => (
                                <li key={product.driver_vehicle_id} className="flex py-6 sm:py-6 px-4 shadow-lg rounded-md">
                                    {/*<div className="flex-shrink-0">
                                        <img
                                            src={product.photo}
                                            alt={product.driver_vehicle_id}
                                            className="w-16 h-16 rounded-md object-center object-cover sm:w-20 sm:h-20"
                                        />
                                    </div>*/}

                                    <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                            <div>
                                                <div className="flex justify-between">
                                                    <h3 className="text-base">
                                                        <p className="font-semibold text-gray-700 hover:text-gray-800">
                                                            {product.plate_no}
                                                        </p>
                                                    </h3>
                                                </div>
                                                <p className="mt-1 text-basefont-medium text-gray-900">{product.make}</p>                                    
                                                <div className="mt-1 flex text-sm">
                                                    <p className="text-gray-500">{product.model} {product.year}</p>
                                                </div>
                                            </div>

                                            <div className="mt-4 sm:mt-0">                                                                                                
                                                <div className="absolute top-0 right-12">
                                                    <button type="button" onClick={(e)=>handleEdit(product.driver_vehicle_id)} className="-m-2 p-2 bg-indigo-600 inline-flex text-white rounded-md">
                                                        <span className="sr-only">Edit</span>
                                                        <PencilIcon className="h-5 w-5 " aria-hidden="true" />
                                                    </button>
                                                </div>                                                                                                
                                            </div>
                                            <div className="mt-4 sm:mt-0">                                                                                                
                                                <div className="absolute top-0 right-0">
                                                    <button type="button" onClick={(e)=>handleDelete(product.driver_vehicle_id)} className="-m-2 p-2 bg-indigo-600 inline-flex text-white rounded-md">
                                                        <span className="sr-only">Remove</span>
                                                        <TrashIcon className="h-5 w-5 " aria-hidden="true" />
                                                    </button>
                                                </div>                                                                                                
                                            </div>
                                        </div>

                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
