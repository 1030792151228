import { BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import {reactLocalStorage} from 'reactjs-localstorage';
import configuration from './config';
import { toast } from 'react-toastify';

import TrackerRoute from "./layout/TrackerRoute";

// Add Components
import AddDriver from "./pages/Driver/Add";

// Edit Components
import EditDriver from "./pages/Driver/Edit";
import EditDispatcher from "./pages/Dispatcher/Edit";
import EditPartner from "./pages/Partner/Edit";
import EditCompany from "./pages/Company/Edit";
import EditBilling from "./pages/Billing/Edit";

// List Components
import Dashboard from "./pages/Dashboard/View";
import Orders from "./pages/Orders/View";
import ForgotPassword from "./pages/ForgotPassword/View";
import GuestRoute from "./layout/GuestRoute";
import SignIn from "./pages/SignIn/View";
import OTP from "./pages/OTP/View";
import Reset from "./pages/Reset/View";
import TemplateRoute from "./layout/TemplateRoute";
import PageNotFound from "./pages/404/View";

import Driver from "./pages/Driver/View";
import DriverProfile from "./pages/Driver/Profile";
// import Billing from "./pages/Billing/View";

import TopRidePassengers from "./pages/Report/UserReport/top-ride-passengers";
import TopRideDrivers from "./pages/Report/UserReport/top-ride-drivers";
import TopBookingCompany from "./pages/Report/UserReport/top-booking-company";
import TopBookingPartner from "./pages/Report/UserReport/top-booking-partner";
import TopBookingDispatcher from "./pages/Report/UserReport/top-booking-dispatcher";
import AndroidUsers from "./pages/Report/UserReport/android-users";
import IosUsers from "./pages/Report/UserReport/ios-users";

import SalesRevenue from "./pages/Report/FinancialReport/sales";
import NetProfits from "./pages/Report/FinancialReport/net-profit";
import Discount from "./pages/Report/FinancialReport/discount";
import TaxReport from "./pages/Report/FinancialReport/tax-report";
import AdditionalChargeReport from "./pages/Report/FinancialReport/additional-charge-report";

import TopRatePassengers from "./pages/Report/TripReport/top-rate-passengers";
import TopRateDrivers from "./pages/Report/TripReport/top-rate-drivers";
import SuccessTrips from "./pages/Report/TripReport/success-trips";
import CancelTrips from "./pages/Report/TripReport/cancel-trips";

import Tracker from "./pages/Tracker/View";

import Trips from "./pages/Trips/View";
import TripsDetail from "./pages/Trips/TripsDetail";
import Invoice from "./pages/Trips/Invoice";
import CreateRequest from "./pages/CreateRequest/Add";

// Manage Routes
function App() {
  useEffect(() => {
    configuration.getAPI({url:'common/get-settings', params:{}}).then((data) => {
      if(data.status === 200){
        document.title = data.payload.app_name
        const dark_mode = document.getElementById('style-direction');
        dark_mode.href = (reactLocalStorage.get('dark_mode') === 'yes')?'/css/tailwind-dark.css':'/css/tailwind-light.css';

        const css_name = document.getElementById('style-color');
        css_name.href = `/css/${(data.payload.admin_color)?data.payload.admin_color:data.payload.admin_color}.css`;
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  return (
    <main>
      <Router>
        <Switch>
          <GuestRoute exact path="/" component={SignIn} />
          <GuestRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute exact path="/otp" component={OTP} />
          <GuestRoute exact path="/reset" component={Reset} />
          <TemplateRoute exact path="/dashboard" component={Dashboard} />
          <TemplateRoute exact path="/orders" component={Orders} />
          
          <TemplateRoute exact path="/driver" component={Driver} />
          <TemplateRoute exact path="/driver/add" component={AddDriver} />
          <TemplateRoute exact path="/driver/edit" component={EditDriver} />
          <TemplateRoute exact path="/driver/profile" component={DriverProfile} />
          <TrackerRoute exact path="/tracker" component={Tracker} />

          <TemplateRoute exact path="/trips" component={Trips} />
          <TemplateRoute exact path="/tripsdetail/:id" component={TripsDetail} />
          <TemplateRoute exact path="/invoice/:id" component={Invoice} />

          <TemplateRoute exact path="/dispatcher/edit" component={EditDispatcher} />
          <TemplateRoute exact path="/partner/edit" component={EditPartner} />
          <TemplateRoute exact path="/company/edit" component={EditCompany} />
          <TemplateRoute exact path="/billing/edit" component={EditBilling} /> 

          <TemplateRoute exact path="/create-request" component={CreateRequest} />
          <TemplateRoute exact path="/top-ride-passengers" component={TopRidePassengers} />
          <TemplateRoute exact path="/top-ride-drivers" component={TopRideDrivers} />
          <TemplateRoute exact path="/top-booking-company" component={TopBookingCompany} />
          <TemplateRoute exact path="/top-booking-partner" component={TopBookingPartner} />
          <TemplateRoute exact path="/top-booking-dispatcher" component={TopBookingDispatcher} />
          <TemplateRoute exact path="/android-users" component={AndroidUsers} />
          <TemplateRoute exact path="/ios-users" component={IosUsers} />
          <TemplateRoute exact path="/sales" component={SalesRevenue} />
          <TemplateRoute exact path="/net-profit" component={NetProfits} />
          <TemplateRoute exact path="/discount" component={Discount} />
          <TemplateRoute exact path="/tax-report" component={TaxReport} />
          <TemplateRoute exact path="/additional-charge-report" component={AdditionalChargeReport} />
          <TemplateRoute exact path="/success-trip" component={SuccessTrips} />
          <TemplateRoute exact path="/cancel-trip" component={CancelTrips} />
          <TemplateRoute exact path="/top-rate-passengers" component={TopRatePassengers} />
          <TemplateRoute exact path="/top-rate-drivers" component={TopRateDrivers} />

          <GuestRoute component={PageNotFound} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
