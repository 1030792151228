export default {
  TopRidePassengers: "TopRidePassengers",
  TopRideDrivers: "TopRideDrivers",
  TopBookingCompany: "TopBookingCompany",
  TopBookingPartner: "TopBookingPartner",
  TopBookingDispatcher: "TopBookingDispatcher",
  SalesRevenue: "SalesRevenue",
  NetProfits: "NetProfits",
  Discount: "Discount",
  TaxReport: "TaxReport",
  AdditionalChargeReport: "AdditionalChargeReport",
  SuccessTrips: "SuccessTrips",
  CancelTrips: "CancelTrips",
  TopRatePassengers: "TopRatePassengers",
  TopRateDrivers: "TopRateDrivers",
  IosUsers: "IosUsers",
  AndroidUsers: "AndroidUsers",
};
