import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import configuration from '../../config';
import React, { useState } from "react";
import GoogleMapReact from 'google-map-react';

function View({ history }) {
  const AnyReactComponent = ({ icon }) => <div><img style={{ width: "40px" }} src={icon} /></div>;
  let defaultSettings = {
    center: {
      lat: 23.0260094693156,
      lng: 72.55570555792589
    },
    zoom: 12,

  };

  const notificationMethods = [
    { id: 'All', title: 'All' },
    { id: 'Online', title: 'Online' },
    { id: 'Offline', title: 'Offline' },
  ]

  const notificationMethods2 = [
    { id: 'Ongoing Trips ', title: 'Ongoing Trips ' },
    { id: 'Multiple Stops Trips', title: 'Multiple Stops Trips' },
    { id: 'Outstation Trips', title: 'Outstation Trips' },
    { id: 'Rental Trips ', title: 'Rental Trips ' },
  ]
  const exampleMapStyles = [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#e9e9e9"
          },
          {
              "lightness": 17
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f5f5f5"
          },
          {
              "lightness": 20
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 17
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 29
          },
          {
              "weight": 0.2
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 18
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f5f5f5"
          },
          {
              "lightness": 21
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#dedede"
          },
          {
              "lightness": 21
          }
      ]
  },
  {
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "saturation": 36
          },
          {
              "color": "#333333"
          },
          {
              "lightness": 40
          }
      ]
  },
  {
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f2f2f2"
          },
          {
              "lightness": 19
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fefefe"
          },
          {
              "lightness": 20
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#fefefe"
          },
          {
              "lightness": 17
          },
          {
              "weight": 1.2
          }
      ]
  }
];

  return (
    <div className="bg-gray-50 flex flex-col justify-center">
      <div className="grid grid-cols-1 md:grid-cols-4 ">
        <div className=" py-4 px-4 shadow-lg bg-white" >
          <h3 className="font-bold text-lg mb-5">Live Trips</h3>

          <div>
            <label className="text-base font-medium text-gray-900">Driver</label>
            <fieldset className="mt-4">
              <legend className="sr-only">Notification method</legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                {notificationMethods.map((notificationMethod) => (
                  <div key={notificationMethod.id} className="flex items-center">
                    <input
                      id={notificationMethod.id}
                      name="notification-method"
                      type="radio"
                      defaultChecked={notificationMethod.id === 'email'}
                      className="focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={notificationMethod.id} className="ml-3 block text-basefont-medium text-gray-700">
                      {notificationMethod.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          <div>
            <p className="text-base font-medium text-gray-900 mt-5">
              Ride Options
            </p>
            <select
              id="location"
              name="location"
              className="w-full mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-baserounded-md"
              defaultValue="Canada"
            >
              <option>XLCAB Go</option>
              <option>XLCAB SUV</option>
              <option>XLCAB Sedan</option>
            </select>
          </div>
          <div>
            <p className="text-base font-medium text-gray-900 mt-5">
              Mobility Options
            </p>
            <select
              id="location"
              name="location"
              className="w-full mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-baserounded-md"
              defaultValue="Canada"
            >
              <option>Green Ride</option>
              <option>With Women Driver</option>
              <option>HandyCap</option>
            </select>
          </div>
          <div>
            <p className="text-base font-medium text-gray-900 mt-5">
              By Trip ID
            </p>
            <input
              type="email"
              name="email"
              id="email"
              className="mt-1 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 block w-full sm:text-baseborder-gray-300 rounded-md"
              placeholder="ID"
            />
          </div>

          <div>
            <p className="text-base font-medium text-gray-900 mt-5">
              By Driver ID
            </p>
            <input
              type="email"
              name="email"
              id="email"
              className="mt-1 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 block w-full sm:text-baseborder-gray-300 rounded-md"
              placeholder="ID"
            />
          </div>
          <div>
            <p className="text-base font-medium text-gray-900 mt-5">
              By Passenger ID
            </p>
            <input
              type="email"
              name="email"
              id="email"
              className="mt-1 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 block w-full sm:text-baseborder-gray-300 rounded-md"
              placeholder="ID"
            />
          </div>

          <div>
            <p className="text-base font-medium text-gray-900 mt-5">Trips Type</p>
            <fieldset className="mt-4">
              <div className="grid grid-cols-1 gap-4">
                {notificationMethods2.map((notificationMethod) => (
                  <div key={notificationMethod.id} className="flex items-center">
                    <input
                      id={notificationMethod.id}
                      name="notification-method"
                      type="radio"
                      defaultChecked={notificationMethod.id === 'email'}
                      className="focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={notificationMethod.id} className="ml-3 block text-basefont-medium text-gray-700">
                      {notificationMethod.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>





        </div>
        <div className="col-span-3" style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyBJ1MqZJ3VwBSqJhU9ThvxwK0O5Q2IT_s4" }}
            defaultCenter={defaultSettings.center}
            defaultZoom={defaultSettings.zoom}
            options={{
                styles: exampleMapStyles
            }}
          >
            <AnyReactComponent
              lat={defaultSettings.center.lat}
              lng={defaultSettings.center.lng}
              icon="/image/pin.png"
            />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {
  history: PropTypes.func
};

View.defaultProps = {
  history: () => { }
};

export default View;
