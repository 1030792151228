
import Breadcrumb from "../../parts/Breadcrumb";
import { React, useEffect, Fragment, useState } from 'react'
import { Dialog, Transition, RadioGroup } from '@headlessui/react'
import labels from "../../constants/CreateRequest";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, isEmpty } from 'underscore';
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import TextInput from "../../components/Form/TextInput";
import Autocomplete from "../../components/Form/PlacesAutocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { geocodeByAddress } from 'react-places-autocomplete';

const plans4 = [
  { id: 1, title: 'One-way', description: 'Get dropped off', type: 'oneway' },
  { id: 2, title: 'Round trip', description: 'Keep the car till re…', type: 'round' },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Add({ history }) {
  var today = new Date();
  today.setHours(today.getHours() + 1);
  const [open, setOpen] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [selectedDate, handleDateChange] = useState(today);
  const [currency, setCurrency] = useState('$')
  const [centerLocation, setCenterLocation] = useState({ lat: 23.0260094693156, lng: 72.55570555792589 })
  const zoomMap = 12;
  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState({ code: '' })
  const [mobility, setMobility] = useState([])
  const [selectedMobility, setSelectedMobility] = useState({})
  const [packages, setPackage] = useState([])
  const [insuranceData, setInsuranceData] = useState([])
  const [insuranceAmount, setinsuranceAmount] = useState(0)
  const [selectedPackage, setSelectedPackage] = useState({})
  const [selectedTripType, setSelectedTripType] = useState({})
  const [showVehiclePrice, setShowVehiclePrice] = useState(false)
  const [vehiclePrice, setVehiclePrice] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState({})
  const [priceInfo, setPriceInfo] = useState({
    features: [],
    fare: []
  })
  const fieldArr = [
    { id: 1, title: "Mobile", name: "mobile", type: "TextInput", is_number: true, is_full_screen: true },
    { id: 2, title: "First Name", name: "first_name", type: "TextInput" },
    { id: 2, title: "Last Name", name: "last_name", type: "TextInput" },
    { id: 3, title: "Email", name: "email", type: "TextInput", is_full_screen: true },
  ];

  const [errors, setErrors] = useState({});

  const [fields, setFields] = useState({
    notes: ''
  });

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.first_name) {
      error['first_name'] = "Please enter first name"
      flag = false
    }

    if (!fields.last_name) {
      error['last_name'] = "Please enter last name"
      flag = false
    }

    if (!fields.mobile) {
      error['mobile'] = "Please enter mobile"
      flag = false
    }
    if (!fields.email) {
      error['email'] = "Please enter email"
      flag = false
    }

    if (!fields.start_address) {
      error['start_address'] = "Please enter start address"
      flag = false
    }
    if (!fields.finish_address && selectedService.code !== 'RENTAL') {
      error['finish_address'] = "Please enter finish address"
      flag = false
    }
    if (!selectedService || isEmpty(selectedService)) {
      error['service_id'] = "Please select service"
      flag = false
    }
    if (!selectedVehicle || isEmpty(selectedVehicle)) {
      error['vehicle_ride_option_id'] = "Please select vehicle ride option"
      flag = false
    }
    console.log(error)
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    getServices();
    configuration.getAPI({ url: 'common/get-settings', params: {} }).then((data) => {
      if (data.status === 200) {
        setCurrency(data.payload.currency)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'insurance/list', params: {}, backUrl: true }).then((data) => {
      if (data.status === 200) {
        setInsuranceData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);
  function getServices() {
    configuration.getAPI({ url: 'service/get-service', params: {}, backUrl: true }).then((data) => {
      if (data.status === 200 && data.payload.length > 0) {
        setServices(data.payload)
        setSelectedService(data.payload[0])
        getMobility()
      } else if (data.error && data.error.message) {
        return toast.error(data.error.message)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function getMobility() {
    configuration.getAPI({ url: 'common/get-mobility', params: { type: 'other' } }).then((data) => {
      if (data.status === 200 && data.payload.length > 0) {
        setMobility(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function getPackage() {
    configuration.getAPI({ url: 'common/get-package', params: {} }).then((data) => {
      if (data.status === 200 && data.payload.length > 0) {
        setPackage(data.payload)
        setSelectedPackage(data.payload[0])
        if (fields.start_latitude && fields.start_longitude) {
          const sendData = {
            pick_up_location: fields.start_address,
            pick_up_latitude: fields.start_latitude,
            pick_up_longitude: fields.start_longitude,
            from_city: fields.from_city,
            type: 'RENTAL',
            rental_package_id: data.payload[0].rental_package_id
          }
          getListRide(sendData)
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function getListRide(sendData) {
    let apiURL = 'trip/list-ride';
    let newSendData = {}
    console.log(sendData)
    if (sendData.type === 'RENTAL') {
      apiURL = 'trip/list-rental'
    }
    if (sendData.type === 'OUT_STATION') {
      apiURL = 'trip/list-outstation'
      newSendData = {
        start_address: sendData.pick_up_location,
        start_latitude: sendData.pick_up_latitude,
        start_longitude: sendData.pick_up_longitude,
        from_city: sendData.from_city,
        finish_address: sendData.drop_off[0].location,
        finish_latitude: sendData.drop_off[0].latitude,
        finish_longitude: sendData.drop_off[0].longitude,
        to_city: sendData.drop_off[0].to_city,
        outstation_trip_type: sendData.outstation_trip_type,
      }
    }
    configuration.getAPIAxios({ url: apiURL, params: (sendData.type === 'OUT_STATION') ? newSendData : sendData }).then((data) => {
      if (data.status === 200 && data.payload) {
        setFields({
          ...fields,
          start_address: sendData.pick_up_location,
          start_latitude: sendData.pick_up_latitude,
          start_longitude: sendData.pick_up_longitude,
          from_city: sendData.from_city,
          finish_address: sendData.drop_off ? sendData.drop_off[0].location : '',
          finish_latitude: sendData.drop_off ? sendData.drop_off[0].latitude : 0,
          finish_longitude: sendData.drop_off ? sendData.drop_off[0].longitude : 0,
          to_city: sendData.drop_off ? sendData.drop_off[0].to_city : '',
          city_id: data.payload.city_id || '',
          total_distance: data.payload.total_distance || '',
          formatted_total_distance: data.payload.formatted_total_distance || '',
          total_duration: data.payload.total_duration || '',
          formatted_total_duration: data.payload.formatted_total_duration || ''
        })
        setVehiclePrice(data.payload.rides);
        setShowVehiclePrice(true);
      } else if (data.error && data.error.message) {
        return toast.error(data.error.message)
      }
    }).catch(error => {
      console.log(error)
      return toast.error(error.message)
    });
  }

  function handleChange(field, e) {
    setFields({ ...fields, [field]: (field === 'start_address' || field === 'finish_address') ? e : e.target.value })
  }

  function handleAddInsurance() {
    setFields({ ...fields, is_insurance_taken: 'yes', insurance_amount: insuranceAmount })
    setOpen5(false)
    setinsuranceAmount(0)
  }

  function handleService(e) {
    setSelectedService(e);
    setSelectedVehicle({})
    console.log(fields)
    if (e.code === 'RIDE') {
      getMobility()
      setShowVehiclePrice(false);
      if (fields.start_latitude && fields.start_longitude && fields.from_city && fields.finish_latitude && fields.finish_longitude) {
        const sendData = {
          pick_up_location: fields.start_address,
          pick_up_latitude: fields.start_latitude,
          pick_up_longitude: fields.start_longitude,
          drop_off: [{
            location: fields.finish_address,
            latitude: fields.finish_latitude,
            longitude: fields.finish_longitude,
            to_city: fields.to_city,
          }],
          type: e.code,
          from_city: fields.from_city
        }
        getListRide(sendData)
      }
    }
    if (e.code === 'RENTAL') {
      getPackage()
      setShowVehiclePrice(false);
    }
    if (e.code === 'OUT_STATION') {
      setShowVehiclePrice(false);
      setSelectedTripType(plans4[0])
      if (fields.start_latitude && fields.start_longitude && fields.from_city && fields.finish_latitude && fields.finish_longitude && fields.to_city) {
        const sendData = {
          pick_up_location: fields.start_address,
          pick_up_latitude: fields.start_latitude,
          pick_up_longitude: fields.start_longitude,
          drop_off: [{
            location: fields.finish_address,
            latitude: fields.finish_latitude,
            longitude: fields.finish_longitude,
            to_city: fields.to_city,
          }],
          type: e.code,
          from_city: fields.from_city,
          outstation_trip_type: plans4[0].type,
        }
        getListRide(sendData)
      }
    }
  }
  function handleSelect(field, e) {
    geocodeByAddress(e)
      .then(response => {
        const { lat, lng } = response[0].geometry.location;
        if (field === 'start_address') {
          let from_city = '';
          each(response[0].address_components, singleData => {
            if ((singleData.types).includes("locality")) {
              from_city = singleData.long_name
            }
          })
          setCenterLocation({ lat, lng })
          if (fields.finish_latitude && fields.finish_longitude && from_city && lat() && lng()) {
            const sendData = {
              pick_up_location: e,
              pick_up_latitude: lat(),
              pick_up_longitude: lng(),
              drop_off: [{
                location: fields.finish_address,
                latitude: fields.finish_latitude,
                longitude: fields.finish_longitude,
                to_city: fields.to_city,
              }],
              type: selectedService.code,
              from_city: from_city,
              outstation_trip_type: selectedTripType ? selectedTripType.type : ''
            }
            getListRide(sendData)
          } else if (selectedService.code === 'RENTAL') {
            const sendData = {
              pick_up_location: e,
              pick_up_latitude: lat(),
              pick_up_longitude: lng(),
              from_city: from_city,
              type: selectedService.code,
              rental_package_id: selectedPackage.rental_package_id
            }
            getListRide(sendData)
          } else {
            setFields({ ...fields, [field]: e, start_latitude: lat(), start_longitude: lng(), from_city })
          }
        }
        if (field === 'finish_address') {
          let to_city = '';
          each(response[0].address_components, singleData => {
            if ((singleData.types).includes("locality")) {
              to_city = singleData.long_name
            }
          })
          setCenterLocation({ lat, lng })
          if (fields.start_latitude && fields.start_longitude && fields.from_city && lat() && lng()) {
            const sendData = {
              pick_up_location: fields.start_address,
              pick_up_latitude: fields.start_latitude,
              pick_up_longitude: fields.start_longitude,
              drop_off: [{
                location: e,
                latitude: lat(),
                longitude: lng(),
                to_city: to_city
              }],
              type: selectedService.code,
              from_city: fields.from_city,
              outstation_trip_type: selectedTripType ? selectedTripType.type : ''
            }
            getListRide(sendData)
          } else {
            setFields({ ...fields, [field]: e, finish_latitude: lat(), finish_longitude: lng(), to_city: to_city })
          }
        }
      }).catch(error => {
        console.log('error', error);
      });
  }
  function handleMobility(e) {
    setSelectedMobility(e)
    if (selectedService.code === 'RENTAL') {
      if (fields.start_latitude && fields.start_longitude && fields.from_city) {
        const sendData = {
          pick_up_location: fields.start_address,
          pick_up_latitude: fields.start_latitude,
          pick_up_longitude: fields.start_longitude,
          from_city: fields.from_city,
          type: selectedService.code,
          rental_package_id: selectedPackage.rental_package_id,
          vehicle_mobility_id: e.vehicle_mobility_id
        }
        getListRide(sendData)
      }
    } else {
      if (fields.start_latitude && fields.start_longitude && fields.from_city && fields.finish_latitude && fields.finish_longitude) {
        const sendData = {
          pick_up_location: fields.start_address,
          pick_up_latitude: fields.start_latitude,
          pick_up_longitude: fields.start_longitude,
          drop_off: [{
            location: fields.finish_address,
            latitude: fields.finish_latitude,
            longitude: fields.finish_longitude,
            to_city: fields.to_city,
          }],
          type: selectedService.code,
          from_city: fields.from_city,
          vehicle_mobility_id: e.vehicle_mobility_id,
          outstation_trip_type: selectedTripType.type,
        }
        getListRide(sendData)
      }
    }
  }
  function handlePackage(e) {
    setSelectedPackage(e)
    if (fields.start_latitude && fields.start_longitude && fields.from_city) {
      const sendData = {
        pick_up_location: fields.start_address,
        pick_up_latitude: fields.start_latitude,
        pick_up_longitude: fields.start_longitude,
        from_city: fields.from_city,
        type: 'RENTAL',
        rental_package_id: e.rental_package_id
      }
      getListRide(sendData)
    }
  }
  function handleTripType(e) {
    setSelectedTripType(e)
    console.log(fields)
    if (fields.start_latitude && fields.start_longitude && fields.from_city && fields.finish_latitude && fields.finish_longitude && fields.to_city) {
      const sendData = {
        pick_up_location: fields.start_address,
        pick_up_latitude: fields.start_latitude,
        pick_up_longitude: fields.start_longitude,
        drop_off: [{
          location: fields.finish_address,
          latitude: fields.finish_latitude,
          longitude: fields.finish_longitude,
          to_city: fields.to_city,
        }],
        type: selectedService.code,
        from_city: fields.from_city,
        outstation_trip_type: e.type,
      }
      getListRide(sendData)
    }
  }
  function handleBlur(field, e) {
    if (field === 'mobile') {
      console.log(field)
      console.log(e.target.value)
      configuration.getAPI({ url: 'sub-user/list', params: { mobile: e.target.value, user_type: 'passenger' } }).then((data) => {
        if (data.status === 200 && data.payload.length > 0) {
          setFields({ ...fields, user_id: data.payload[0].user_id, first_name: data.payload[0].first_name, last_name: data.payload[0].last_name, email: data.payload[0].email })
        } else {
          setFields({ ...fields, first_name: '', last_name: '', email: '', user_id: '' })
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handlePriceInfo(single) {
    console.log(single)
    // setPriceInfo(single)
    if (selectedService.code === 'RENTAL') {
      let sendData = {
        vehicle_ride_option_id: single.vehicle_ride_option_id,
        rental_price_id: single.rental_price_id
      }
      configuration.getAPI({ url: 'trip/price-information', params: sendData }).then((data) => {
        if (data.status === 200) {
          setOpen3(true)
          setPriceInfo(data.payload)

        } else if (data.error && data.error.message) {
          return toast.error(data.error.message)
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    } else if (selectedService.code === 'RIDE') {
      setOpen3(true)
      setPriceInfo({
        ...single,
        total: single.fare,
        features: [],
        fare: single.fare_info_display
      })
    } else {
      setOpen3(true)
      setPriceInfo({
        ...single,
        total: single.fare,
        features: [],
        fare: []
      })
    }
  }
  function handleSheduleRide() {
    setOpen(false)
    // if(selectedService.code === 'RENTAL'){
    //   if(fields.start_latitude && fields.start_longitude && fields.from_city){
    //     const sendData ={
    //       pick_up_location:fields.start_address,
    //       pick_up_latitude:fields.start_latitude,
    //       pick_up_longitude:fields.start_longitude,
    //       from_city:fields.from_city,
    //       type:selectedService.code,
    //       rental_package_id:selectedPackage.rental_package_id,
    //       vehicle_mobility_id:selectedMobility.vehicle_mobility_id
    //     }
    //     getListRide(sendData)
    //   }
    // } else {
    //   if(fields.start_latitude && fields.start_longitude && fields.from_city && fields.finish_latitude && fields.finish_longitude){
    //     const sendData ={
    //       pick_up_location:fields.start_address,
    //       pick_up_latitude:fields.start_latitude,
    //       pick_up_longitude:fields.start_longitude,
    //       drop_off:[{
    //         location:fields.finish_address,
    //         latitude:fields.finish_latitude,
    //         longitude:fields.finish_longitude,
    //         to_city:fields.to_city,
    //       }],
    //       type:selectedService.code,
    //       from_city:fields.from_city,
    //       vehicle_mobility_id:selectedMobility.vehicle_mobility_id,
    //       outstation_trip_type:selectedTripType.type,
    //     }
    //     getListRide(sendData)
    //   }
    // }
  }
  function handleBookTrip(field, e) {
    if (handleValidation()) {
      setOpen1(true)
      // console.log(selectedService);
      // console.log(selectedMobility);
      // console.log(selectedVehicle);
      // console.log(fields);
      let tripData = {}
      let apiURL = ''
      if (selectedService.code === 'RIDE') {
        apiURL = 'trip/book';
        tripData = {
          trip_info_json: {
            is_new_user: (fields.user_id || fields.user_id !== '') ? false : true,
            user_id: fields.user_id || '',
            first_name: fields.first_name,
            last_name: fields.last_name,
            mobile: fields.mobile,
            email: fields.email,
            subtotal: selectedVehicle.fare,
            total: selectedVehicle.fare,
            fare_info: selectedVehicle.fare_info,
            total_distance: fields.total_distance,
            formatted_total_distance: fields.formatted_total_distance,
            total_duration: fields.total_duration,
            formatted_total_duration: fields.formatted_total_duration,
            pick_up_location: fields.start_address,
            pick_up_latitude: fields.start_latitude,
            pick_up_longitude: fields.start_longitude,
            vehicle_mobility_id: selectedMobility.vehicle_mobility_id,
            notes: fields.notes,
            is_insurance_taken: fields.is_insurance_taken || "no",
            insurance_amount: fields.insurance_amount || 0,
            drop_off: [{
              location: fields.finish_address,
              latitude: fields.finish_latitude,
              longitude: fields.finish_longitude,
            }],
            vehicle_ride_option_id: selectedVehicle.vehicle_ride_option_id,
            type: "now",
            booking_date: moment().format('YYYY-MM-DD'),
            payment_option: "personal",
            payment_type: "cash",
            book_by: "other",
          },
        }
      } else if (selectedService.code === 'RENTAL') {
        apiURL = 'trip/book-rental';
        tripData = {
          is_new_user: (fields.user_id || fields.user_id !== '') ? false : true,
          user_id: fields.user_id || '',
          first_name: fields.first_name,
          last_name: fields.last_name,
          mobile: fields.mobile,
          email: fields.email,
          pick_up_location: fields.start_address,
          pick_up_latitude: fields.start_latitude,
          pick_up_longitude: fields.start_longitude,
          rental_price_id: selectedVehicle.rental_price_id,
          vehicle_ride_option_id: selectedVehicle.vehicle_ride_option_id,
          vehicle_mobility_id: selectedMobility.vehicle_mobility_id,
          type: "now",
          booking_date: moment().format('YYYY-MM-DD'),
          payment_option: "personal",
          payment_type: "cash",
          notes: fields.notes,
          is_insurance_taken: fields.is_insurance_taken || "no",
          insurance_amount: fields.insurance_amount || 0,
          subtotal: selectedVehicle.fare,
          total: selectedVehicle.fare,
          fare_info: selectedVehicle.fare_info,
          book_by: "other",
        }
      } else if (selectedService.code === 'OUT_STATION') {
        apiURL = 'trip/book-outstation';
        tripData = {
          trip_info_json: {
            is_new_user: (fields.user_id || fields.user_id !== '') ? false : true,
            user_id: fields.user_id || '',
            first_name: fields.first_name,
            last_name: fields.last_name,
            mobile: fields.mobile,
            email: fields.email,
            total_distance: fields.total_distance,
            formatted_total_distance: fields.formatted_total_distance,
            total_duration: fields.total_duration,
            formatted_total_duration: fields.formatted_total_duration,
            outstation_trip_type: selectedTripType.type,
            pick_up_location: fields.start_address,
            pick_up_latitude: fields.start_latitude,
            pick_up_longitude: fields.start_longitude,
            drop_off: [{
              location: fields.finish_address,
              latitude: fields.finish_latitude,
              longitude: fields.finish_longitude,
            }],
            type: "now",
            booking_date: moment().format('YYYY-MM-DD'),
            fare_info: selectedVehicle.fare_info,
            subtotal: selectedVehicle.fare,
            total: selectedVehicle.fare,
            vehicle_mobility_id: selectedMobility.vehicle_mobility_id,
            vehicle_ride_option_id: selectedVehicle.vehicle_ride_option_id,
            notes: fields.notes,
            is_insurance_taken: fields.is_insurance_taken || "no",
            insurance_amount: fields.insurance_amount || 0,
            payment_option: "personal",
            payment_type: "cash",
            book_by: "other",
          },
        }
      }
      configuration.postAPI({ url: apiURL, params: tripData }).then((data) => {
        setOpen1(false)
        // console.log(data)
        if (data.status === 200) {
          toast.success(`Your booking id is #${data.payload.trip_id}, Your ride will start soon...`)
          history.push('/trips')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }


  const AnyReactComponent = ({ icon }) => <div><img alt='icon' style={{ width: "40px" }} src={icon} /></div>;

  const exampleMapStyles = [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e9e9e9"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 29
        },
        {
          "weight": 0.2
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 18
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dedede"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "saturation": 36
        },
        {
          "color": "#333333"
        },
        {
          "lightness": 40
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2f2f2"
        },
        {
          "lightness": 19
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 17
        },
        {
          "weight": 1.2
        }
      ]
    }
  ];

  return (
    <>
      <Breadcrumb module={labels.CreateRequest} />
      <div className="py-6 sm:px-6 ">

        <div className="grid grid-cols-1 lg:grid-cols-1">
          <div className="py-8 col-span-2 px-4">
            <Caption title="Create Request" />
          </div>
          <div className="min-h-screen bg-gray-100 grid grid-cols-1 xl:grid-cols-3">

            <div className="relative  col-span-1 xl:col-span-2 min-height">
              <GoogleMapReact
                /* bootstrapURLKeys={{ key: "AIzaSyBJ1MqZJ3VwBSqJhU9ThvxwK0O5Q2IT_s4" }} */
                bootstrapURLKeys={{ key: configuration.googleAPIKey }}
                defaultCenter={centerLocation}
                defaultZoom={zoomMap}
                options={{
                  styles: exampleMapStyles
                }}
              >
                {(fields.start_latitude && fields.start_latitude !== '' && fields.start_longitude && fields.start_longitude !== '') ?
                  <AnyReactComponent
                    lat={fields.start_latitude}
                    lng={fields.start_longitude}
                    text="Pick up location"
                    icon="/image/pin.png"
                  /> : ''
                }
                {(fields.finish_latitude && fields.finish_latitude !== '' && fields.finish_longitude && fields.finish_longitude !== '') ?
                  <AnyReactComponent
                    lat={fields.finish_latitude}
                    lng={fields.finish_longitude}
                    text="Drop off location"
                    icon="/image/pin.png"
                  /> : ''
                }
              </GoogleMapReact>
            </div>
            <div className="py-5 px-5 shadow-md">
              <div className="mx-auto w-full">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {fieldArr.map((field) => {
                    return (
                      <div className={(field.is_full_screen) ? "sm:col-span-6" : "sm:col-span-3"} key={field.id}>
                        <Label title={field.title} />
                        <div className="mt-1">
                          {field.type === "TextInput" ? (
                            <TextInput
                              id={field.name}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={fields[field.name]}
                              name={field.name}
                              is_number={field.is_number || false}
                              is_password={field.is_password || false}
                            />
                          ) : field.type === "Dropdown" ? (
                            <Dropdown
                              id={field.name}
                              name={field.name}
                              handleChange={handleChange}
                              value={fields[field.name]}
                              status={field.options || []}
                            />
                          ) : field.type === "TextArea" ? (
                            <TextArea
                              id={field.name}
                              name={field.name}
                            />
                          ) : null}
                        </div>
                        {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                      </div>
                    );
                  })}
                </div>

                <div class="xi mt-4 py-4 px-4 shadow-lg bg-white rounded-md">
                  <div class="relative ml-10">
                    <Autocomplete
                      class="custom_input from_input bor"
                      id={`start_address`}
                      name={`start_address`}
                      handleChange={handleChange}
                      handleSelect={handleSelect}
                      value={fields.start_address}
                    />
                    {/* <input name="pickup" title="" placeholder="54, rue du Gue Jacquet" autocomplete="off" class="custom_input from_input bor" /> */}
                  </div>
                  {(selectedService.code === 'RENTAL') ? '' :
                    <div class="sn centerdrop">
                      <div class="relative ml-10">
                        <img className='switch_pin cursor-pointer' src='./image/switch.png' alt='' />
                        <Autocomplete
                          class="custom_input from_input bor"
                          id={`finish_address`}
                          name={`finish_address`}
                          handleChange={handleChange}
                          handleSelect={handleSelect}
                          value={fields.finish_address}
                        />
                      </div>
                    </div>
                  }
                  {/* <div class="sn">
                  <div class="relative ml-10">
                    <input name="destination" title="" placeholder="Rue des Nations Unies" autocomplete="off" class="custom_input from_input bor" />
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 add_loc" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    <img className='fav' src='./image/favorite.svg' alt=""/>
                  </div>
                </div> */}
                  <div class=" xy xz  yc "></div>
                </div>



                <RadioGroup className="mt-5" value={selectedService} onChange={(e) => handleService(e)}>
                  <RadioGroup.Label className="text-base font-medium text-gray-900">Select Service</RadioGroup.Label>

                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {services.map((service) => (
                      <RadioGroup.Option
                        key={service.service_id}
                        value={service}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? 'border-transparent' : 'border-gray-300',
                            active ? 'ring-2 ring-yellow-600' : '',
                            'relative bg-white border rounded-lg shadow-sm p-4 text-center cursor-pointer focus:outline-none'
                          )
                        }
                      >
                        {({ checked, active }) => (
                          <>
                            <div className="">
                              {/* <div className="" onClick={() => selectService(service.title)}> */}
                              <RadioGroup.Label as="span" className="block text-base font-medium text-gray-900">
                                <img className="w-28 mb-3 mt-3 mx-auto" src={service.icon} alt="" />
                              </RadioGroup.Label>
                              <RadioGroup.Label as="span" className="block text-lg font-medium text-gray-900">
                                {service.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="span" className="mt-1  text-base text-gray-500">
                                {(service.is_discount) ? (service.discount_type === 'percentage') ? `${service.discount_value}% OFF` : `${currency} ${service.discount_value} OFF` : ''}
                              </RadioGroup.Description>
                              {/* </div> */}
                            </div>
                            <CheckCircleIcon
                              className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-yellow-600 absolute right-2 top-2')}
                              aria-hidden="true"
                            />
                            <div
                              className={classNames(
                                active ? 'border' : 'border-2',
                                checked ? 'border-yellow-600' : 'border-transparent',
                                'absolute -inset-px rounded-lg pointer-events-none'
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>


                {(selectedService.code === 'RENTAL') ?
                  <>
                    <div className='flex items-center  mt-5 justify-between'>
                      <div>
                        <h3 className='font-bold text-lg'>{selectedPackage.hr} hrs {selectedPackage.km} km package</h3>
                        <h3 className='font-normal text-gray-400 text-base'>Extra charges on exceeding package.</h3>
                      </div>
                      <div className=''>
                        <h3 onClick={() => setOpen6(true)} className='font-medium text-yellow-600 text-base cursor-pointer'>View Details</h3>
                      </div>
                    </div>
                    <div className='rental-hours mt-5'>
                      <RadioGroup value={selectedPackage} onChange={(e) => handlePackage(e)}>
                        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                        <div className="flex overflow-x-auto py-2 px-2 max-w-max gap-4 cus_scroll">
                          {packages.map((plan) => (
                            <RadioGroup.Option
                              key={`${plan.hr} hr`}
                              value={plan}
                              className={({ checked, active }) =>
                                classNames(
                                  checked ? 'border-transparent' : 'border-gray-300',
                                  active ? 'ring-2 ring-gray-600' : '',
                                  'relative bg-white border rounded-lg shadow-sm px-3 cursor-pointer sm:flex sm:justify-between focus:outline-none cus_w'
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label as="p" className="font-medium text-gray-400 ">
                                        <h3 className='font-bold text-base text-gray-600 mt-2'>{`${plan.hr} hr`}</h3>
                                        <p className='font-normal text-sm'>{`${plan.km} km`}</p>
                                      </RadioGroup.Label>
                                    </div>
                                  </div>
                                  <div
                                    className={classNames(
                                      active ? 'border' : 'border-2',
                                      checked ? 'border-gray-600' : 'border-transparent',
                                      'absolute -inset-px rounded-lg pointer-events-none'
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </>
                  : ""}

                {(selectedService.code === 'OUT_STATION') ?
                  <>
                    <div className='outstation mt-8'>
                      <RadioGroup className="mt-5" value={selectedTripType} onChange={(e) => handleTripType(e)}>
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                          {plans4.map((plan) => (
                            <RadioGroup.Option
                              key={plan.id}
                              value={plan}
                              className={({ checked, active }) =>
                                classNames(
                                  checked ? 'border-transparent' : 'border-gray-300',
                                  active ? 'ring-2 ring-yellow-600' : '',
                                  'relative bg-white border rounded-lg shadow-sm p-4  cursor-pointer focus:outline-none'
                                )
                              }
                            >
                              {({ checked, active }) => (
                                <>
                                  <div className="">
                                    <div className="">
                                      <RadioGroup.Label as="span" className="block text-base font-bold text-gray-900">
                                        {plan.title}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description as="span" className="mt-1  text-base text-gray-500">
                                        {plan.description}
                                      </RadioGroup.Description>
                                    </div>
                                  </div>
                                  <CheckCircleIcon
                                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-yellow-600 absolute right-2 top-2')}
                                    aria-hidden="true"
                                  />
                                  <div
                                    className={classNames(
                                      active ? 'border' : 'border-2',
                                      checked ? 'border-yellow-600' : 'border-transparent',
                                      'absolute -inset-px rounded-lg pointer-events-none'
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </>
                  : ""}


                <div className='flex items-center  mt-5 justify-between'>
                  <h3 className='font-bold text-lg'>Choose Vehicle</h3>
                  {(showVehiclePrice) ?
                    <div className='flex gap-4'>
                      <img onClick={() => setOpen(true)} className='cursor-pointer' src='./image/calendar.svg' alt='' />
                      <img onClick={() => setOpen4(true)} className='cursor-pointer' src='./image/note.svg' alt='' />
                    </div> : ''
                  }
                </div>
                <div className='mobilityoption mt-5'>
                  <RadioGroup value={selectedMobility} onChange={(e) => handleMobility(e)}>
                    <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 ">
                      {mobility.map((plan) => (
                        <RadioGroup.Option
                          key={plan.title}
                          value={plan}
                          className={({ checked, active }) =>
                            classNames(
                              checked ? 'border-transparent' : 'border-gray-300',
                              active ? 'ring-2 ring-gray-600' : '',
                              'relative bg-white border rounded-lg shadow-sm px-3 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div className="flex items-center">
                                <div className="text-sm">
                                  <RadioGroup.Label as="p" className="font-medium text-gray-400 flex items-center">
                                    <img className="w-6 h-6 mb-2 mt-2 mx-auto mr-5" src={plan.icon} alt="" />
                                    <p className='font-normal text-sm'>{plan.title}</p>
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              <div
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-gray-600' : 'border-transparent',
                                  'absolute -inset-px rounded-lg pointer-events-none'
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                {(showVehiclePrice) ?
                  <div className='selectvehicle mt-5 mb-10'>
                    <RadioGroup value={selectedVehicle} onChange={(e) => {
                      setSelectedVehicle(e)
                      setinsuranceAmount(0)
                      setFields({ ...fields, is_insurance_taken: 'no', insurance_amount: 0 })
                    }}>
                      {vehiclePrice.map((single, indx) => {
                        return (<div className="">
                          <RadioGroup.Option
                            key={single.title}
                            value={single}
                            className={({ checked }) =>
                              classNames(
                                checked ? 'bg-yellow-50 border-yellow-600 border-2 z-10' : 'bg-white',
                                'relative rounded-md mb-3  cursor-pointer focus:outline-none border-2 border-white'
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <div className="flex items-center justify-between p-4">
                                  <div className={classNames(checked ? 'text-yellow-900' : 'text-gray-900', 'block text-basefont-medium')}>
                                    <div className='flex'>
                                      <h3 className='mr-3 font-bold text-lg text-gray-600'>{single.title}</h3> <img onClick={(e) => handlePriceInfo(single)} src='./image/information.svg' alt="" />
                                    </div>
                                    <p className='text-base text-gray-600 font-normal'>{single.max_seats} Seats</p>
                                    <div className='flex justify-between mt-3'>
                                      <p className='font-bold text-lg text-yellow-600 mr-10'>{currency} {Number(single.fare).toFixed(2)}</p>
                                    </div>
                                  </div>
                                  <div>
                                    <p className={classNames(checked ? 'text-yellow-700' : 'text-gray-500', 'block text-sm')}>
                                      <img className='w-24' src={single.icon ? single.icon : './image/go.png'} alt="" />
                                    </p>
                                  </div>
                                </div>
                                {(checked) ?
                                  <div onClick={() => {
                                    setOpen5(true)
                                    setinsuranceAmount(single.insurance_amount)
                                  }} className='flex items-center justify-between bg-yellow-600 px-3 py-2 rounded-br-md rounded-bl-md'>
                                    <div className='flex items-center'>
                                      <img className='mr-3' src="./image/insurance.svg" alt="" /> <p>Insure your rides at just {currency} {single.insurance_amount}</p>
                                    </div>
                                    <img className='mr-3' src="./image/next.svg" alt="" />
                                  </div> : ''
                                }
                              </>
                            )}
                          </RadioGroup.Option>
                        </div>)
                      })}
                    </RadioGroup>
                    {errors.vehicle_ride_option_id ? <Label title={'vehicle_ride_option_id'} fieldError={errors.vehicle_ride_option_id} /> : null}
                  </div> : ''
                }

                {/*  <button
                    type="button"
                    disabled={!showVehiclePrice}
                    onClick={handleBookTrip}
                    className="w-full mt-5 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  >
                    Book Now
                  </button> */}
                <div className='sticky bottom-0 w-full z-10'>
                  {(fields.is_insurance_taken === 'yes') ?
                    <div className='flex items-center justify-between bg-gray-600 px-3 py-2  text-white'>
                      <div className='flex items-center'>
                        <img className='mr-3' src="./image/insurance2.svg" alt="" /> <p>{currency} {fields.insurance_amount} ride insurance added</p><img onClick={() => setOpen5(true)} className='ml-3 cursor-pointer' src="./image/information2.svg" alt="" />
                      </div>
                      <p className='text-yellow-600 cursor-pointer' onClick={(e) => {
                        setinsuranceAmount(0)
                        setFields({ ...fields, is_insurance_taken: 'no', insurance_amount: 0 })
                      }}>Remove</p>
                    </div> : ''
                  }
                  <div className='flex items-center justify-between bg-white px-3 py-3'>
                    <button
                      type="button"
                      disabled={!showVehiclePrice}
                      onClick={handleBookTrip}
                      className="w-full  px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    >
                      BOOK NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      When do you want to be picked up?
                    </Dialog.Title>
                    <div className="mt-5 cus_date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          variant="inline"
                          label="Select Date"
                          value={selectedDate}
                          onChange={handleDateChange}
                          disablePast={true}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="mt-5 cus_date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          variant="inline"
                          label="Select Time"
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto"
                    onClick={handleSheduleRide}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open4} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen4(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    onClick={() => setOpen4(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Note to driver
                    </Dialog.Title>
                    <p className='text-gray-600 font-normal'>Let driver know more about your requests.</p>
                    <div className="mt-5">
                      <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        value={fields.notes}
                        onChange={(e) => handleChange('notes', e)}
                        className="shadow-sm focus:ring-yellow-600 focus:border-yellow-600 block w-full sm:text-baseborder-gray-300 rounded-md"
                      // defaultValue={fields.notes}
                      />

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen4(false)}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen4(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open3} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen3(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    onClick={() => {
                      setOpen3(false)
                      setPriceInfo({
                        features: [],
                        fare: []
                      })
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {priceInfo.title}
                    </Dialog.Title>
                    <p className='text-gray-400 font-normal'>{priceInfo.caption}</p>
                    <div className="mt-5">
                      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-4">
                        {(selectedService.code === 'RENTAL') ?
                          priceInfo.features.map((single, indx) => {
                            return (<dt>
                              <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                              <p className="ml-9 text-lg leading-6 font-normal text-gray-900">{single}</p>
                            </dt>)
                          })
                          :
                          <>
                            <dt>
                              <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                              <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Comfy Hatch</p>
                            </dt>
                            <dt>
                              <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                              <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Luggage Space</p>
                            </dt>
                            <dt>
                              <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                              <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Pocket Friendly</p>
                            </dt>
                            <dt>
                              <CheckCircleIcon className="absolute h-6 w-6 text-gray-600" aria-hidden="true" />
                              <p className="ml-9 text-lg leading-6 font-normal text-gray-900">Cashless Rides</p>
                            </dt>
                          </>
                        }
                      </div>
                      <p className='text-gray-900 text-lg font-bold mt-5'>Estimated fare : {currency} {Number(priceInfo.total).toFixed(2)}</p>

                      {/* <p className='text-gray-900 text-lg font-normal mt-2'>Applied rate card</p> */}
                      <dl className="text-base mt-5">
                        {priceInfo.fare.map((single, indx) => {
                          return (<div className="pb-2 flex items-center justify-between">
                            <dt className="text-gray-400 font-normal">{single.label}</dt>
                            <dd className="font-normal text-gray-400">{currency} {Number(single.value).toFixed(2)}</dd>
                          </div>)
                        })}
                      </dl>
                      <div className='mt-2'>
                        <p className="font-normal text-gray-400">Extra changes on exceeding package. GST and toll, if applicable, will be added to the bill. Please pay parking as and when requiyellow. Base fare amount is the minimum bill amount a customer has to pay for the package.</p>
                      </div>
                      <hr className='mt-4' />

                      <h3 className='text-lg font-bold mt-4'>Our fleet</h3>

                      <img className='w-56' src='./image/mini-car.png' alt='' />
                      <p className="font-normal text-gray-400">Tata Indica, Nissan Micra, Maruti Ritz</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      setOpen3(false)
                      setPriceInfo({
                        features: [],
                        fare: []
                      })
                    }}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen3(false)
                      setPriceInfo({
                        features: [],
                        fare: []
                      })
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open6} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen6(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    onClick={() => setOpen6(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Rental package rules & restrictions
                    </Dialog.Title>
                    <p className='text-gray-400 font-normal'>For usage beyond 2 hrs 20 km package, additional fare will be applicable as per your selected category.</p>
                    <div className="mt-5">
                      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-4">
                        <dt className='border border-gray-200 py-3 px-3'>
                          <h3 className='text-lg'>Prime Sedan</h3>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹12 / km </p>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹2.5 / min</p>
                        </dt>
                        <dt className='border border-gray-200 py-3 px-3'>
                          <h3 className='text-lg'>Mini</h3>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹12 / km </p>
                          <p className="text-lg leading-6 font-normal text-gray-400">₹2 / min</p>
                        </dt>
                      </div>
                      <p className='text-gray-400 text-base font-normal mt-5'>Rental can be used for local travels only. Package cannot be changed after booking is confirmed.</p>
                      <p className='text-gray-400 text-base font-normal mt-5'>For usage beyond selected package, additional fare will be applicable as per rates above.</p>
                      <p className='text-gray-400 text-base font-normal mt-5'>Additional GST applicable on fare. Toll will be added in the final bill if applicable, please pay parking fee when requiyellow.  </p>
                      <p className='text-gray-400 text-base font-normal mt-5'>For ride lated bookings, advance booking fee of ₹100 will be added to total fare.  </p>
                      <p className='text-gray-400 text-base font-normal mt-5'>Base fare amount is the minimum bill amount a customer has to pay for the package </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen6(false)}
                  >
                    Done
                  </button>

                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen6(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open5} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen5(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    onClick={() => {
                      setOpen5(false)
                      setinsuranceAmount(0)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">

                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Insure all your rental rides at {currency} {insuranceAmount} per ride
                    </Dialog.Title>
                    <p className='text-gray-400 font-normal'>Ride carefree & enjoy all benefits. You can opt out any time from the profile section of the app.</p>
                    <div className="mt-5">
                      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-4">
                        {insuranceData.map((single, indx) => {
                          return (<dt className='border border-gray-200 py-3 px-3'>
                            <h3 className='text-lg'>{single.title}</h3>
                            <p className="text-lg leading-6 font-normal text-gray-400">{single.benefit}  </p>
                          </dt>)
                        })}
                      </div>
                      {/* <p className='text-gray-400 text-base font-normal mt-5'>By ICICI general insurance XLCAB <Link to="" className='text-yellow-600'>T&C</Link></p> */}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600 sm:ml-3 sm:w-auto"
                    onClick={handleAddInsurance}
                  >
                    Add Ride Insurance
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen5(false)
                      setinsuranceAmount(0)
                    }}
                  >
                    Remind Me Later
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen1(true)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className='text-center mt-4 py-4 px-4 bg-white rounded-md'>
                  <img className='mt-5 w-10 mb-5 mx-auto' src='./image/spinner.gif' alt="" />
                  <h3 className='text-lg font-bold text-gray-600'>We are processing your booking...</h3>
                  <p className='mt-3 text-base font-normal text-gray-600'>Your ride will start soon</p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default Add;
